import React, { useEffect, useState } from "react";
import AppTextInput from "components/AppTextInput";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Button,
} from "@mui/material";
import { downloadFileAPI } from "services/API/fileManager";
import { Modal, ModalContent } from "components/Images/ModalImage";
import { useDispatch } from "react-redux";
import {
  getCardsLimitsRequestAction,
  setCardsLimitsRequestAction,
} from "redux/actions/cardActions";
import { LoadingButton } from "@mui/lab";

const CardLimits = ({ cardId }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (cardId) {
      dispatch(getCardsLimitsRequestAction(cardId, setData));
    }
  }, [cardId]);

  const handleChange = (event, name) => {
    setData({
      ...data,
      [name]: event.target.value,
    });
  };

  return (
    <Card>
      <CardHeader title="Card Limits" />
      <CardContent>
        <Grid container xs={12} spacing={4}>
          {data &&
            Object.keys(data).map((item) => {
              return (
                <Grid item xs={4}>
                  <AppTextInput
                    label={item}
                    value={data[item]}
                    onChange={(event) => handleChange(event, item)}
                    dir="ltr"
                    // disabled={basicInfo.deleted_at != null ? true : false}
                  />
                </Grid>
              );
            })}
        </Grid>
      </CardContent>
      <CardActions>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => {
            dispatch(
              setCardsLimitsRequestAction({ ...data, cardId }, setLoading)
            );
          }}
        >
          Submit
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default CardLimits;
