import React, { useEffect, useState } from "react";
import {Card,CardContent, Box, Grid, Typography,Link } from "@mui/material";
import {CardTitle} from "reactstrap";
import {
  getPepBalancesAction,
} from "redux/actions/usersActions";
import { useDispatch } from "react-redux";
import DataCard from "components/DataCard";
// --------------------------------------------------------------------------------
const PepBalances = () => {
  const dispatch = useDispatch();
  const [balances, setBalances] = useState([]);
  
  useEffect(() => {
    dispatch(getPepBalancesAction({ setBalances }));
  }, [setBalances, dispatch]);
  return (
    <Card sx={{p:2}}>
      <CardTitle>Pep Balances</CardTitle>
      <CardContent sx={{ display: "flex", flexFlow: "wrap"}}>
        {balances.map((item) => {
            return (
              <DataCard title={item.balance} subTitle={item.symbol} />
            );
        })}
      </CardContent>
    </Card>
  );
};

export default PepBalances;
