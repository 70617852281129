import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPendingUsersRequestAction } from "./../../../../../redux/actions/customerActions";
import CustomTable from "components/CustomTable";
// --------------------------------------------------------------------------------
const PendingUsers = () => {
  const dispatch = useDispatch();

  const { pendingUsers } = useSelector((state) => ({
    pendingUsers: state.customer.pendingUsers,
  }));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const permissions = useSelector((state) => state.auth.user.permissions);

  useEffect(() => {
    dispatch(
      getPendingUsersRequestAction({
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullname",
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "User's Full Name",
        Cell: ({ cell, row }) => {
          return (
            <Link to={`${row.original.id}`} target="_blank">
              {cell.getValue()}
            </Link>
          );
        },
      },
      {
        accessorKey: "agent",
        header: "Agent",
        size: 150,
      },
      // {
      //   accessorKey: "email", //normal accessorKey
      //   header: "Email",
      //   size: 200,
      //   Cell: ({ cell, row }) => (
      //     <Link to={`/app/profile/${row.original.id}/Summary`}>
      //       {cell.getValue()}
      //     </Link>
      //   ),
      // },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        Cell: ({ cell, row }) => {
          return permissions.some((p) => p === "Search Users") &&
            row.original.pepStatus == "starter" ? (
            <Link to={`/app/profile/${row.original.id}/Summary`}>
              {cell.getValue()}
            </Link>
          ) : (
            <span>{cell.getValue()}</span>
          );
        },
      },
      {
        accessorKey: "country_code",
        header: "Mobile Country Code",
        size: 150,
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
        size: 150,
      },
      {
        accessorKey: "registerTime",
        header: "Register Time",
        size: 150,
      },
    ],
    []
  );

  return (
    <CustomTable
      title="Pending Users"
      data={pendingUsers.records}
      columns={columns}
      onPaginationChange={setPagination}
      state={{ pagination, loading: false }}
      rowCount={pendingUsers.count}
    />
  );
};

export default PendingUsers;
