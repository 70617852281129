export const superAdmin = "super admin";

export const subjects = [
    [{ name: "ALL", id: 0 }],
    [{ name: "User", id: 'User' }],
    [{ name: "ClientUser", id: 'ClientUser' }],
    [{ name: "Topup", id: 'Topup' }],
    [{ name: "TopupCard", id: 'TopupCard' }],
    [{ name: "Card", id: 'Card' }],
    [{ name: "BankAccount", id: 'BankAccount' }],
    [{ name: "Agent", id: 'Agent' }],
    [{ name: "Role", id: 'Role' }],
]