import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import App from "./containers/App";
import Modal from "components/Modal";
import { ThemeProvider } from "@mui/material";
import { defaultTheme } from "containers/themes/defaultTheme";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // ignoreTransactions: ["partial/match"],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const MainApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider theme={defaultTheme}>
          <App />
          <Modal />
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default MainApp;
