import React, { useEffect, useState } from "react";
import DataCard from "components/DataCard";
import useAPI from "services/hook";
import APIs from "services/API/Main";
import Loading from "components/Loading/index";
import Divider from "@mui/material/Divider";
import MainTable from "components/MainTable/index";
import { dateFormatter } from "constants/usefulFunctions";
import SalesStatistic from "./SalesStatistic";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import UsersBalances from "./UsersBalances";
import PepBalances from "./PepBalances";
import { useSelector } from "react-redux";
import { superAdmin } from "constant";

const LineChart = () => {
  const [nium, setNium] = useState([]);
  const [userBalance, setUserBalance] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);

  const roles = useSelector((state) => state.auth.user.roles);



  const [loading, setLoading] = useState(false);

  const { request } = useAPI(APIs.getDashbordInfo);

  const getDashboardData = async () => {
    const data = await request();
    const nium = [];
    const user_balance = [];

    data?.nium_balance?.forEach((item) => {
      nium.push({
        title: item.balance + " ",
        subTitle: item.currency,
        background: "primary",
        loading: false,
      });
    });

    data?.user_balance?.forEach((item) => {
      user_balance.push({
        title: item.balance + " ",
        subTitle: item.currency,
        background: "primary",
        loading: false,
      });
    });

    setTransactionsData(data?.transaction);
    setNium(nium);
    setUserBalance(user_balance);
    setLoading(false);
  };

  if (loading) return <Loading />;
  return (
    <div className="animated slideInUpTiny animation-duration-3">
      <div className="col-12">
        <SalesStatistic />
        <UsersBalances />
        {roles.some((role) => role.name === superAdmin) && <PepBalances />}
      </div>
    </div>
  );
};

export default LineChart;
