import {
  CALCULATE_FEE_FAILURE,
  CALCULATE_FEE_STARTED,
  CALCULATE_FEE_SUCCESS,
} from "redux/ActionTypes";
import {
  calculateFeeAPI,
  calculateAdminFeeAPI,
  getBadgesAPI,
  getCitiesAPI,
  getCountriesAPI,
  getEventsAPI,
  getOccuoationAPI,
  getStatesAPI,
  getTimeZonesAPI,
} from "services/API/General";

export const getCountriesAction = (setCountries) => {
  return async (dispatch) => {
    try {
      let response = await getCountriesAPI();
      if (!response.error) {
        setCountries(response.data);
      } else {
      }
    } catch (error) {}
  };
};
export const getStatesAction = (setStates) => {
  return async (dispatch) => {
    try {
      let response = await getStatesAPI();
      if (!response.error) {
        setStates(response.data);
      } else {
      }
    } catch (error) {}
  };
};
export const getCitiesAction = (setCities) => {
  return async (dispatch) => {
    try {
      let response = await getCitiesAPI();
      if (!response.error) {
        setCities(response.data);
      } else {
      }
    } catch (error) {}
  };
};
export const getOccuoationAction = (setOccuoations) => {
  return async (dispatch) => {
    try {
      let response = await getOccuoationAPI();
      if (!response.error) {
        setOccuoations(response.data);
      } else {
      }
    } catch (error) {}
  };
};
export const getEventsAction = (setEvents) => {
  return async (dispatch) => {
    try {
      let response = await getEventsAPI();
      if (!response.error) {
        setEvents(response.data);
      } else {
      }
    } catch (error) {}
  };
};
export const getTimeZonesAction = (setTimeZones) => {
  return async (dispatch) => {
    try {
      let response = await getTimeZonesAPI();
      if (!response.error) {
        setTimeZones(response.data);
      } else {
      }
    } catch (error) {}
  };
};
export const getBadgesAction = ({ setBadges }) => {
  return async (dispatch) => {
    try {
      let response = await getBadgesAPI();
      if (!response.error) {
        setBadges(response.data);
      } else {
      }
    } catch (error) {}
  };
};

export const calculateFeeRequestAction = ({
  userId,
  eventId,
  walletId,
  setUserLimits,
}) => {
  return async (dispatch) => {
    dispatch(calculateFeeStartedAction());
    try {
      let response = await calculateFeeAPI({ userId, eventId, walletId });
      if (!response.error) {
        dispatch(calculateFeeSuccessAction());
        setUserLimits(response.data.fee);
      } else {
        dispatch(calculateFeeFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(calculateFeeFailureAction(error.error_description));
    }
  };
};

export const calculateAdminFeeRequestAction = ({
  adminId,
  eventId,
  walletId,
  setAdminLimits,
}) => {
  return async (dispatch) => {
    dispatch(calculateFeeStartedAction());
    try {
      let response = await calculateAdminFeeAPI({ adminId, eventId, walletId });
      console.log(response);
      if (!response.error) {
        dispatch(calculateFeeSuccessAction());
        setAdminLimits(response.data.fee);
      } else {
        dispatch(calculateFeeFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(calculateFeeFailureAction(error.error_description));
    }
  };
};

export const calculateFeeStartedAction = () => ({
  type: CALCULATE_FEE_STARTED,
});

export const calculateFeeSuccessAction = (payload) => ({
  type: CALCULATE_FEE_SUCCESS,
  payload,
});

export const calculateFeeFailureAction = (message) => ({
  type: CALCULATE_FEE_FAILURE,
  payload: message,
});
