import React, { useEffect, useState } from "react";
// import UserInfo from "../UserInfo";
import StatusLoading from "../StatusLoading";
import CardHeader from "components/CardHeader";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getUserDetails,
  getUserDocumentsRequestAction,
} from "redux/actions/usersActions";
import ImagePreview from "./ImagePreview";
import { Grid, Typography } from "@mui/material";
// import UserPersonal from "./UserInfo/UserPersonal";
// import UserAddress from "./UserInfo/UserAddress";
// import UserNationality from "./UserInfo/UserNationality";

const Documents = () => {
  const dispatch = useDispatch();
  const { id: userId } = useParams();
  const [user, setUser] = useState({});
  const [address, setAddress] = useState({});
  const [nationality, setNationality] = useState({});
  const [personal, setPersonal] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    if (userId) dispatch(getUserDocumentsRequestAction({ userId, setData }));
  }, [userId]);

  //   useEffect(() => {
  //     setAddress(user.Address);
  //     setNationality(user.Nationality);
  //     setPersonal(user.Personal);
  //   }, [user]);

  return (
    <div className="jr-card jr-full-card">
      <CardHeader title="Documents" status={user?.status} />
      <div style={{ padding: "24px" }}>
        {/* {personal && <UserPersonal data={personal} setData={setPersonal} />}
        <br></br>
        {address && <UserAddress data={address} setData={setAddress} />}
        <br></br>
        {nationality && (
          <UserNationality data={nationality} setData={setNationality} />
        )} */}
        <Grid container spacing={3}>
          {data.documents?.addressProof && (
            <Grid item xs={2}>
              <ImagePreview
                label="Address Proof"
                fileAddress={data.documents?.addressProof}
              />
            </Grid>
          )}
          {data.documents?.cardDeliveryProof && (
            <Grid item xs={2}>
              <ImagePreview
                label="Card Delivery Proof"
                fileAddress={data.documents?.cardDeliveryProof}
              />
            </Grid>
          )}
          {data.documents?.identityProof && (
            <Grid item xs={2}>
              <ImagePreview
                label="Identity Proof"
                fileAddress={data.documents?.identityProof}
              />
            </Grid>
          )}
          {data.documents?.passportProof && (
            <Grid item xs={2}>
              <ImagePreview
                label="Passport Proof"
                fileAddress={data.documents?.passportProof}
              />
            </Grid>
          )}
          {data.documents?.selfie && (
            <Grid item xs={2}>
              <ImagePreview
                label="Selfie"
                fileAddress={data.documents?.selfie}
              />
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Documents;
