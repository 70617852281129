import React, { useEffect, useState } from "react";
import CardHeader from "components/CardHeader";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAdminFeesListAction } from "redux/actions/adminActions";
import AdminFee from "./AdminFee";

const AdminFees = () => {
  const dispatch = useDispatch();
  const { id: adminId } = useParams();
  const [fees, setFees] = useState([]);

  useEffect(() => {
    if (adminId) dispatch(getAdminFeesListAction(adminId, setFees));
  }, [adminId]);


  return (
    <div className="jr-card jr-full-card">
      <CardHeader title="Fees and Limits" />
      <div style={{ padding: "24px", marginBottom: "12px" }}>
        {fees.length
          ? fees.map((fee , index) => {
              if(fee.fees.length !== 0) {
                return <AdminFee data={fee} key={index} />;
              }
            })
          : ""}
      </div>
    </div>
  );
};

export default AdminFees;
