import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const loginAPI = ({ email, password }) => {
  let postData = {
    email,
    password,
  };
  return axiosMiddleware({
    method: "post",
    url: "login",
    data: postData,
  });
};
export const verifyAPI = ({ email, password, verifyCode }) => {
  let postData = {
    email,
    password,
    verifyCode,
  };
  return axiosMiddleware({
    method: "post",
    url: "verify",
    data: postData,
  });
};
export const logoutAPI = ({ userID }) => {
  let postData = {
    userID,
  };
  return axiosMiddleware({
    method: "post",
    url: "logout",
    data: postData,
  });
};
export const forgotPasswordFirstStepAPI = ({ email }) => {
  let postData = {
    email,
  };
  return axiosMiddleware({
    method: "post",
    url: "forgot-step-one",
    data: postData,
  });
};
export const forgotPasswordSecondStepAPI = ({ email, token }) => {
  let postData = {
    email,
    token,
  };
  return axiosMiddleware({
    method: "post",
    url: "forgot-step-two",
    data: postData,
  });
};
export const forgotPasswordThirdStepAPI = ({
  email,
  token,
  newPassword,
  confirmNewPassword,
}) => {
  let postData = {
    email,
    token,
    new_password: newPassword,
    confirm_new_password: confirmNewPassword,
  };
  return axiosMiddleware({
    method: "post",
    url: "forgot-step-three",
    data: postData,
  });
};
