import React, { useEffect, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTopUpIbanAction,
  getTopUpIbanListAction,
} from "redux/actions/topupActions";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomTable from "components/CustomTable";
// --------------------------------------------------------------------------------
const IbanList = () => {
  const dispatch = useDispatch();

  const { ibanList } = useSelector((state) => ({
    ibanList: state.topUp.ibanList,
  }));

  useEffect(() => {
    dispatch(getTopUpIbanListAction());
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "currency",
        header: "Currency",
      },
      {
        accessorKey: "country",
        header: "Country",
      },
      {
        accessorKey: "bank",
        header: "Bank",
      },
      {
        accessorKey: "iban",
        header: "IBAN",
      },
      {
        accessorKey: "holder_name",
        header: "Account Holder Name",
      },
    ],
    []
  );

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this rate?")) {
      dispatch(deleteTopUpIbanAction({ id: row.original.id }));
    }
  };

  return (
    <CustomTable
      title="Iban List"
      data={ibanList}
      columns={columns}
      enableRowActions
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
      manualPagination={false}
    />
  );
};

export default IbanList;
