import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteUserFeeAction,
  getUserFeesRequestAction,
} from "redux/actions/usersActions";
import CustomTable from "components/CustomTable";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const FeesTable = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getUserFeesRequestAction(setData));
  }, []);
  const columns = useMemo(() => [
    {
      header: "Agent",
      accessorKey: "agent",
    },
    {
      header: "Currency",
      accessorKey: "currency",
    },
    {
      header: "Event",
      accessorKey: "event",
    },
    {
      header: "Constant",
      accessorKey: "constant",
    },
    {
      header: "Percent",
      accessorKey: "percent",
    },
    {
      header: "Min",
      accessorKey: "min",
    },
    {
      header: "Max",
      accessorKey: "max",
    },
  ]);

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this rate?")) {
      dispatch(deleteUserFeeAction({ id: row.original.id }, setData));
    }
  };
  return (
    <CustomTable
      title="Fees & Limits"
      columns={columns}
      data={data}
      enableRowActions
      renderRowActions={({ row, table }) => (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
      manualPagination={false}
    />
  );
};

export default FeesTable;
