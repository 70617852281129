import {
  GET_ADMIN_ACTIVITY_LOGS_FAILURE,
  GET_ADMIN_ACTIVITY_LOGS_STARTED,
  GET_ADMIN_ACTIVITY_LOGS_SUCCESS,
  GET_DASHBOARD_ACTIVITY_LOGS_FAILURE,
  GET_DASHBOARD_ACTIVITY_LOGS_STARTED,
  GET_DASHBOARD_ACTIVITY_LOGS_SUCCESS,
  GET_PEP_LOGS_FAILURE,
  GET_PEP_LOGS_STARTED,
  GET_PEP_LOGS_SUCCESS,
  GET_USERS_LOG_FAILURE,
  GET_USERS_LOG_STARTED,
  GET_USERS_LOG_SUCCESS,
} from "redux/ActionTypes";
import { getAdminActivityLogsAPI, getDashboardActivityLogsAPI, getPepLogsAPI, getUsersLogAPI } from "services/API/logs";
// --------------------------------------------------------------------------------
export const getUsersLogRequestAction = ({ limit, offset }) => {
  return async (dispatch) => {
    dispatch(getUsersLogStartedAction());
    try {
      let response = await getUsersLogAPI({ limit, offset });
      if (!response.error) {
        dispatch(getUsersLogSuccessAction({ usersLog: response.data }));
      } else {
        dispatch(getUsersLogFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersLogFailureAction(error.error_description));
    }
  };
};

export const getUsersLogStartedAction = () => ({
  type: GET_USERS_LOG_STARTED,
});

export const getUsersLogSuccessAction = (payload) => ({
  type: GET_USERS_LOG_SUCCESS,
  payload,
});

export const getUsersLogFailureAction = (message) => ({
  type: GET_USERS_LOG_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getPepLogsRequestAction = ({ limit, offset, from, to }) => {
  return async (dispatch) => {
    dispatch(getPepLogsStartedAction());
    try {
      let response = await getPepLogsAPI({ limit, offset, from, to });
      if (!response.error) {
        dispatch(getPepLogsSuccessAction({ pepLogs: response.data }));
      } else {
        dispatch(getPepLogsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getPepLogsFailureAction(error.error_description));
    }
  };
};

export const getPepLogsStartedAction = () => ({
  type: GET_PEP_LOGS_STARTED,
});

export const getPepLogsSuccessAction = (payload) => ({
  type: GET_PEP_LOGS_SUCCESS,
  payload,
});

export const getPepLogsFailureAction = (message) => ({
  type: GET_PEP_LOGS_FAILURE,
  payload: message,
});

// --------------------------------------------------------------------------------
export const getAdminActivityLogsRequestAction = ({ limit, offset, from, to, subject , admin }) => {
  return async (dispatch) => {
    dispatch(getAdminActivityLogsStartedAction());
    try {
      let response = await getAdminActivityLogsAPI({ limit, offset, from, to , subject , admin });
      console.log(response)
      if (!response.error) {
        dispatch(getAdminActivityLogsSuccessAction({ adminActivityLogs: response.data }));
      } else {
        dispatch(getAdminActivityLogsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getAdminActivityLogsFailureAction(error.error_description));
    }
  };
};

export const getAdminActivityLogsStartedAction = () => ({
  type: GET_ADMIN_ACTIVITY_LOGS_STARTED,
});

export const getAdminActivityLogsSuccessAction = (payload) => ({
  type: GET_ADMIN_ACTIVITY_LOGS_SUCCESS,
  payload,
});

export const getAdminActivityLogsFailureAction = (message) => ({
  type: GET_ADMIN_ACTIVITY_LOGS_FAILURE,
  payload: message,
});

// --------------------------------------------------------------------------------
export const getDashboardActivityLogsRequestAction = ({ limit, offset, from, to , subject , admin }) => {
  return async (dispatch) => {
    dispatch(getDashboardActivityLogsStartedAction());
    try {
      let response = await getDashboardActivityLogsAPI({ limit, offset, from, to  , subject , admin });
      if (!response.error) {
        dispatch(getDashboardActivityLogsSuccessAction({ dashboardActivityLogs: response.data }));
      } else {
        dispatch(getDashboardActivityLogsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getDashboardActivityLogsFailureAction(error.error_description));
    }
  };
};

export const getDashboardActivityLogsStartedAction = () => ({
  type: GET_DASHBOARD_ACTIVITY_LOGS_STARTED,
});

export const getDashboardActivityLogsSuccessAction = (payload) => ({
  type: GET_DASHBOARD_ACTIVITY_LOGS_SUCCESS,
  payload,
});

export const getDashboardActivityLogsFailureAction = (message) => ({
  type: GET_DASHBOARD_ACTIVITY_LOGS_FAILURE,
  payload: message,
});
