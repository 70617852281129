import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { GET_STARTER_USERS } from "config/apiRoutes";
import PaginationTable from "components/PaginationTable";
// --------------------------------------------------------------------------------
const StarterUsers = () => {
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "User's Full Name",
        size: 150,
        Cell: ({ cell, row }) => (
          <Link to={`/users/pending-users/${row.original.id}`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "agent",
        header: "Agent",
        size: 150,
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        size: 200,
        Cell: ({ cell, row }) => (
          <Link to={`/app/profile/${row.original.id}/Summary`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "country_code",
        header: "Mobile Country Code",
        size: 150,
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
        size: 150,
      },
      {
        accessorKey: "kycStatus",
        header: "KYC Status",
        size: 150,
      },
      {
        accessorKey: "status",
        header: "User Status",
        size: 150,
      },
      {
        accessorKey: "pepId",
        header: "PeP ID",
        size: 150,
      },
      {
        accessorKey: "pepStatus",
        header: "PeP Status",
        size: 150,
      },
      {
        accessorKey: "pepRejectionReason",
        header: "Pep Rejection Reason",
        size: 250,
      },
      {
        accessorKey: "premiumRequest",
        header: "Premium Request",
        size: 150,
      },
      {
        accessorKey: "registerTime",
        header: "Register Time",
        size: 150,
      },
    ],
    []
  );

  return (
    <PaginationTable
      title="Starter Users"
      columns={columns}
      apiEndpoint={GET_STARTER_USERS}
      keywordSearch
    />
  );
};

export default StarterUsers;
