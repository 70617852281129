import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { ApiURL } from "constants/constants";
import { useParams } from "react-router-dom";
import DefaultUser from "assets/images/default/default-user.png";
import { useDispatch } from "react-redux";
import { getUserInfo } from "redux/actions/usersActions";

const ProfileHeader = () => {
  const dispatch = useDispatch();
  const { id: userId } = useParams();
  const [user, setUser] = useState({});

  useEffect(() => {
    if (userId) dispatch(getUserInfo({ setUser, userId }));
  }, [userId]);
  return (
    <div className="jr-profile-banner">
      <div className="jr-profile-container">
        <div className="jr-profile-banner-top">
          <div className="jr-profile-banner-top-left">
            <div className="jr-profile-banner-avatar ml-3">
              <Avatar
                className="size-90"
                alt="..."
                src={
                  user?.profilePicture
                    ? ApiURL + user?.profilePicture
                    : DefaultUser
                }
              />
            </div>
            <div className="jr-profile-banner-avatar-info">
              <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                {user?.firstName} {user?.lastName}
              </h2>
              <p className="mb-1 text-grey">Pep Id: {user.pepId}</p>
              <p className="mb-1 text-grey">Pep Status: {user.pepStatus}</p>
              <p className="mb-1 text-grey">{user.agentName}</p>
            </div>
          </div>
          <div className="jr-profile-banner-top-right">
            <ul className="jr-follower-list">
              <li>
                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                  {user?.user_plan && user?.user_plan.plan.name_en}
                </span>
                <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                  {user?.user_plan && user?.user_plan.end_date}
                </span>
                {user?.promoter_email && (
                  <a
                    target="_blank"
                    href={`/app/profile/user/${user?.promoter_id}/Summary`}
                    style={{ textDecoration: "none", color: "white" }}
                    rel="noreferrer"
                  >
                    <span className="jr-fs-sm d-block pointer mt-3">
                      Promoter Email{": "}
                      {user?.promoter_email}
                    </span>
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProfileHeader);
