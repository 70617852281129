import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { NumericFormat } from "react-number-format";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import classNames from "classnames";

const useStyles = makeStyles({
  root: (props) => ({
    width: props?.fullWidth ? "100%" : 300,
    margin: 0,
    backgroundColor: props?.backgroundColor,
    ...props.customClass,
  }),
});

const AppNumberInput = ({
  label,
  value,
  onChange,
  variant = "outlined",
  fullWidth = false,
  readOnly = false,
  shrink = true,
  helperText,
  error = false,
  multiline = false,
  rows = 5,
  margin = "0",
  color = "primary",
  dir = "ltr",
  disabled,
  postfix,
  prefix,
  thousandSeparator = true,
  decimalScale = 2,
  maxLength,
  required = false,
  onFocus,
  onBlur,
  backgroundColor,
  customClass,
  ...otherProps
}) => {
  const classes = useStyles({ customClass, fullWidth, backgroundColor });
  return (
    <TextField
      label={label}
      required={required}
      className={classNames(classes.root, customClass)}
      value={value}
      onFocus={onFocus}
      // onBlur={onChange}
      onChange={onChange}
      disabled={disabled}
      variant={variant}
      helperText={helperText}
      error={error}
      dir={dir}
      margin={margin}
      fullWidth={fullWidth}
      color={color}
      InputLabelProps={{
        shrink,
        readOnly,
      }}
      InputProps={{
        inputComponent: NumericFormatCustom,
        inputProps: { decimalScale, maxLength, readOnly },
        startAdornment: prefix ? (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ) : null,
        endAdornment: postfix ? (
          <InputAdornment position="start">{postfix}</InputAdornment>
        ) : null,
      }}
      {...otherProps}
    />
  );
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
    />
  );
});
export default AppNumberInput;
