import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AppButton from "components/AppButton";
import AppTextInput from "components/AppTextInput/index";
import {
  FORGOT_PASSWORD_FIRST_STEP_STARTED,
  FORGOT_PASSWORD_SECOND_STEP_STARTED,
  FORGOT_PASSWORD_THIRD_STEP_STARTED,
  LOGIN_STARTED,
  VERIFY_STARTED,
} from "redux/ActionTypes";
import Logo from "../assets/images/logo-dark.svg";
import {
  forgotPasswordFirstStepRequestAction,
  forgotPasswordSecondStepRequestAction,
  forgotPasswordThirdStepRequestAction,
} from "redux/actions/AuthActions";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rType } = useSelector((state) => state.auth, shallowEqual);

  const [showError, setShowError] = useState(false);
  const [step, setStep] = useState(0);
  const [verify_token, setVerify_token] = useState("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotPasswordFirstStepRequestAction({ email, setStep }));
  };

  const handleForgotSecondStep = (e) => {
    e.preventDefault();
    dispatch(
      forgotPasswordSecondStepRequestAction({
        email,
        token: verify_token,
        setStep,
      })
    );
  };

  const handleForgotThirdStep = (e) => {
    e.preventDefault();
    dispatch(
      forgotPasswordThirdStepRequestAction({
        email,
        token: verify_token,
        newPassword: password,
        confirmNewPassword: confirmPassword,
        navigate,
      })
    );
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-login-content">
          <div className="app-login-header mb-4 text-center">
            <img
              src={Logo}
              alt="Yekpay"
              title="Yekpay"
              className="app-login-header mb-4"
            />
            <h3>Forgot Password</h3>
          </div>
          <form>
            <div className="app-login-form">
              {step !== 2 ? (
                <>
                  <AppTextInput
                    label="Email"
                    fullWidth
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                    customClass="my-3"
                  />
                  {/* <AppTextInput
                type="password"
                label="Password"
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                customClass="my-3"
              /> */}
                  {step === 1 && (
                    <AppTextInput
                      label="Token"
                      fullWidth
                      onChange={(event) => setVerify_token(event.target.value)}
                      value={verify_token}
                      autoFocus
                      customClass="my-3"
                    />
                  )}
                </>
              ) : (
                <>
                  <AppTextInput
                    label="New Passord"
                    fullWidth
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                    autoFocus
                    customClass="my-3"
                    type="password"
                  />
                  <AppTextInput
                    label="Confirm New Password"
                    fullWidth
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    value={confirmPassword}
                    customClass="my-3"
                    type="password"
                  />
                </>
              )}
              <div className="mb-3 mt-3 d-flex align-items-center justify-content-between">
                <AppButton
                  text={
                    step === 0
                      ? "Send Code"
                      : step === 1
                        ? "Verify"
                        : "Change Password"
                  }
                  type="submit"
                  onClick={
                    step === 0
                      ? handleFormSubmit
                      : step === 1
                        ? handleForgotSecondStep
                        : handleForgotThirdStep
                  }
                  variant="contained"
                  color="primary"
                  loading={
                    rType === FORGOT_PASSWORD_FIRST_STEP_STARTED ||
                    rType === FORGOT_PASSWORD_SECOND_STEP_STARTED ||
                    rType === FORGOT_PASSWORD_THIRD_STEP_STARTED
                  }
                  disabled={
                    step === 0
                      ? !email
                      : step === 1
                        ? !verify_token
                        : !password ||
                        !confirmPassword ||
                        password !== confirmPassword
                  }
                />
              </div>
              <AppButton
                text="Back"
                onClick={() => navigate("/login")}
                variant="text"
                color="black"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
