import axiosMiddleware from "./axiosMiddleware";

export const topupUserAPI = ({
  walletId,
  userId,
  amount,
  description,
  fee,
  file,
}) => {
  let postData = {
    walletId,
    userId,
    amount,
    description,
    file,
  };
  return axiosMiddleware({
    method: "post",
    url: "topup/create",
    data: postData,
  });
};

export const topupAdminAPI = ({
  walletId,
  adminId,
  amount,
  description,
  fee,
  file,
}) => {
  let postData = {
    wallet_id: walletId,
    admin_id: adminId,
    amount,
    description,
    file,
  };
  return axiosMiddleware({
    method: "post",
    url: "topup/create-admin",
    data: postData,
  });
};

export const getTopUpListAPI = ({
  limit,
  offset,
  from,
  to,
  agent,
  currency,
}) => {
  let url = `topup/list?limit=${limit}&offset=${offset}`;

  if (agent !== 0) {
    url += `&agentId=${agent}`;
  }
  if (currency !== 0) {
    url += `&currencyId=${currency}`;
  }

  url += `&from=${from}&to=${to}`;

  return axiosMiddleware({
    method: "get",
    url: url,
  });
};

export const getTopUpCardListAPI = ({
  limit,
  offset,
  from,
  to,
  agent,
  currency,
}) => {
  let url = `topup/list?limit=${limit}&offset=${offset}`;

  if (agent !== 0) {
    url += `&agentId=${agent}`;
  }
  if (currency !== 0) {
    url += `&currencyId=${currency}`;
  }

  url += `&from=${from}&to=${to}`;
  return axiosMiddleware({
    method: "get",
    url: url,
  });
};

export const getAllTopUpListAPI = (agent, currency, from, to) => {
  from = from.format("YYYY-MM-DD");
  to = to.format("YYYY-MM-DD");
  let url = `topup/list`;
  url += `?agentId=${agent}&currencyId=${currency}&from=${from}&to=${to}`;
  console.log(url);
  return axiosMiddleware({
    method: "get",
    url: url,
  });
};

export const getAllTopUpCardListAPI = (agent, currency, from, to) => {
  from = from.format("YYYY-MM-DD");
  to = to.format("YYYY-MM-DD");
  let url = `topup/card/list`;
  url += `?agentId=${agent}&currencyId=${currency}&from=${from}&to=${to}`;
  return axiosMiddleware({
    method: "get",
    url: url,
  });
};

export const getAgentTopUpListAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "topup/agent/list",
  });
};

export const getTopUpCountriesAPI = ({ walletId }) => {
  return axiosMiddleware({
    method: "get",
    url: `topup/get-country?currencyId=${walletId}`,
  });
};
export const getTopUpBanksAPI = ({ countryId }) => {
  return axiosMiddleware({
    method: "get",
    url: `topup/get-banks?countryId=${countryId}`,
  });
};
export const createTopUpIbanAPI = ({
  currencyId,
  countryName,
  bankName,
  iban,
  holderName,
}) => {
  let postData = {
    currencyId,
    countryName,
    bankName,
    iban,
    holderName,
  };
  return axiosMiddleware({
    method: "post",
    url: "topup/iban-create",
    data: postData,
  });
};
export const getTopUpIbanListAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "topup/iban-list",
  });
};
export const deleteTopUpIbanAPI = ({ id }) => {
  let deleteData = {
    id,
  };
  return axiosMiddleware({
    method: "delete",
    url: "topup/iban-delete",
    data: deleteData,
  });
};
