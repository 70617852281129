import React, { useEffect, useState, memo, useCallback } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import useAPI from "services/hook/index";
import CircularProgress from "components/CircularProgress/index";
import { isEmpty } from "lodash";
import classNames from "classnames";

const useStyles = makeStyles({
  root: {
    width: ({ fullWidth }) => (fullWidth ? "100%" : 300),
    // margin: 10,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
  },
});

const AppDropDown = ({
  label,
  value,
  onChange,
  variant = "outlined",
  fullWidth = false,
  readOnly = false,
  shrink = true,
  helperText,
  error = false,
  margin,
  color = "primary",
  disabled,
  items = [],
  required,
  onFocus,
  onBlur,
  schema = { label: "label", value: "value", extraLabel: "" },
  backgroundColor,
  apiAddress,
  dependValue,
  dependKey,
  name,
  keyMap,
  filterItemByValue,
  filterItemByKey,
  requestOnMount,
  extraItem,
  customClass,
  SelectProps,
}) => {
  const classes = useStyles({ fullWidth, backgroundColor });

  const [loading, setLoading] = useState(true);
  const [dropDownItems, setDropDownItems] = useState([]);
  const [localFilteredItems, setLocalFilteredItems] = useState([]);

  const api = useAPI(apiAddress);

  const fetchItems = useCallback(async () => {
    setLoading(true);
    const itemsArr = [];
    const result = await api?.request(
      dependValue && dependKey ? { [dependKey]: dependValue } : null
    );
    console.log(12323,result)
    if (keyMap) {
      result?.map((item) => {
        return itemsArr.push(item?.[keyMap]);
      });
      setDropDownItems(itemsArr);
    } else {
      setDropDownItems(result);
    }
    setLoading(false);
  }, [dependValue, dependKey, api]);

  useEffect(() => {
    if (filterItemByValue && filterItemByKey && !isEmpty(dropDownItems)) {
      const localFilteredItems = dropDownItems?.filter((dItem) =>
        Object.keys(dItem).find(
          (i) => i === filterItemByKey && dItem[i] !== filterItemByValue
        )
      );

      setLocalFilteredItems(localFilteredItems);
    }
  }, [filterItemByValue, dropDownItems]);

  useEffect(() => {
    if ((apiAddress && dependValue && dependKey) || requestOnMount)
      fetchItems();
    else {
      setDropDownItems(items);
      setLoading(false);
    }
  }, [dependValue, dependKey]);

  const finalItems = !isEmpty(localFilteredItems)
    ? [extraItem ?? [], ...localFilteredItems]
    : [extraItem ?? [], ...dropDownItems];

  return (
    <TextField
      name={name}
      select
      label={label}
      className={classNames(classes.root, customClass)}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled || loading}
      variant={variant}
      helperText={helperText}
      error={error}
      items={items}
      margin={margin}
      fullWidth={fullWidth}
      required={required}
      InputLabelProps={{
        shrink,
        readOnly,
      }}
      InputProps={{
        endAdornment: (
          <React.Fragment>
            {loading ? (
              <CircularProgress className="mx-3" color="inherit" size={20} />
            ) : null}
          </React.Fragment>
        ),
      }}
      SelectProps={SelectProps}
    >
      {finalItems?.map((item) => {
        if (isEmpty(item)) return null;
        return (
          <MenuItem
            key={item[schema.value]}
            value={item[schema.value]}
            className={item?.className}
          >
            {item[schema.label]}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default memo(AppDropDown);
