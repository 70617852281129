import {
  GET_TOP_UP_BANKS_FAILURE,
  GET_TOP_UP_BANKS_STARTED,
  GET_TOP_UP_BANKS_SUCCESS,
  GET_TOP_UP_CARD_LIST_FAILURE,
  GET_TOP_UP_CARD_LIST_STARTED,
  GET_TOP_UP_CARD_LIST_SUCCESS,
  GET_TOP_UP_COUNTRIES_FAILURE,
  GET_TOP_UP_COUNTRIES_STARTED,
  GET_TOP_UP_COUNTRIES_SUCCESS,
  GET_TOP_UP_IBAN_LIST_FAILURE,
  GET_TOP_UP_IBAN_LIST_STARTED,
  GET_TOP_UP_IBAN_LIST_SUCCESS,
  LOGIN_DEFAULT,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: LOGIN_DEFAULT,
  topUpCardList: { records: [], count: 0 },
  topUpCountries: [],
  topUpBanks: [],
  ibanList: [],
  error: "",
};
export const TopUpCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOP_UP_CARD_LIST_STARTED:
    case GET_TOP_UP_COUNTRIES_STARTED:
    case GET_TOP_UP_BANKS_STARTED:
    case GET_TOP_UP_IBAN_LIST_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_TOP_UP_CARD_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_TOP_UP_CARD_LIST_SUCCESS,
        topUpCardList: action.payload.topUpCardList,
        error: "",
      };
    case GET_TOP_UP_COUNTRIES_SUCCESS:
      return {
        ...state,
        rType: GET_TOP_UP_COUNTRIES_SUCCESS,
        topUpCountries: action.payload.topUpCountries,
        error: "",
      };
    case GET_TOP_UP_BANKS_SUCCESS:
      return {
        ...state,
        rType: GET_TOP_UP_COUNTRIES_SUCCESS,
        topUpBanks: action.payload.topUpBanks,
        error: "",
      };
    case GET_TOP_UP_IBAN_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_TOP_UP_IBAN_LIST_SUCCESS,
        ibanList: action.payload.ibanList,
        error: "",
      };
    case GET_TOP_UP_CARD_LIST_FAILURE:
    case GET_TOP_UP_COUNTRIES_FAILURE:
    case GET_TOP_UP_BANKS_FAILURE:
    case GET_TOP_UP_IBAN_LIST_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
