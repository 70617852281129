import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const createExchangeRateAPI = ({
  sourceCurrencyId,
  targetCurrencyId,
  rate,
}) => {
  let postData = {
    sourceCurrencyId,
    targetCurrencyId,
    rate,
  };
  return axiosMiddleware({
    method: "post",
    url: "exchange/rate/create",
    data: postData,
  });
};
export const getExchangeRateListAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "exchange/rate/list",
  });
};
export const deleteExchangeRateAPI = ({ id }) => {
  let deleteData = {
    id,
  };
  return axiosMiddleware({
    method: "delete",
    url: "exchange/rate/delete",
    data: deleteData,
  });
};
