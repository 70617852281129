import React, { useEffect, useState } from "react";
import {Card,CardContent, Box, Grid, Typography,Link } from "@mui/material";
import {CardTitle} from "reactstrap";
import {
  getUsersBalancesAction,
} from "redux/actions/usersActions";
import { useDispatch } from "react-redux";
import DataCard from "components/DataCard";
// --------------------------------------------------------------------------------
const UsersBalances = () => {
  const dispatch = useDispatch();
  const [balances, setBalances] = useState([]);

  useEffect(() => {
    dispatch(getUsersBalancesAction({ setBalances }));
  }, [setBalances, dispatch]);
  return (
    <Card sx={{p:2,mb:2}}>
      <CardTitle>Users Balance</CardTitle>

      <CardContent sx={{ display: "flex", flexFlow: "wrap"}}>
        {balances.map((user) => {
            return (
              <DataCard title={user.balance} subTitle={user.symbol} />
            );
        })}
      </CardContent>
    </Card>
  );
};

export default UsersBalances;
