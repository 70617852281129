import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteDefaultWalletRequestAction } from "redux/actions/agentActions";
import { editAgentDefaultWalletRequestAction } from "../../../../../redux/actions/agentActions";

const FeesCard = ({ wallet, currencies }) => {
  const dispatch = useDispatch();

  const [selectedCurrencies, setSelectedCurrencies] = useState([]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={currencies.map((currency) => currency.code)}
              getOptionLabel={(option) => option}
              defaultValue={wallet.currencies}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Wallet" />}
              onChange={(e, c) => setSelectedCurrencies(c)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Agent" value={wallet.agent} fullWidth disabled />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() =>
              dispatch(deleteDefaultWalletRequestAction({ id: wallet.id }))
            }
            color="error"
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() =>
              dispatch(
                editAgentDefaultWalletRequestAction({
                  id: wallet.id,
                  currencies: selectedCurrencies,
                })
              )
            }
          >
            Save
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default FeesCard;
