import MessageDialog, {
  INFORMATION_DIALOG,
} from "components/MessageDialog/index";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import APIs from "services/API";
import useAPI from "services/hook";
import AppCheckbox from "components/AppCheckbox";
import AppTextInput from "components/AppTextInput";
import AppButton from "components/AppButton";
import Loading from "components/Loading";
import { useNavigate } from "react-router-dom";
import { Card, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel } from "@mui/material";
import { TRUE } from "sass";

// --------------------------------------------------------------------------------
const AddRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: roleId } = useParams();

  const [forms, setForms] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkAll, setCheckAll] = useState({});

  const [dialogProps, setDialogProps] = useState({
    isOpen: false,
    type: INFORMATION_DIALOG, 
  });

  const apiCreateRole = useAPI(APIs.apiCreateRole);
  const apiStoreRole = useAPI(APIs.apiStoreRole);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await getForms();
      if (roleId && location.state) {
        setRoleName(location.state.name);
        setPermissions(location.state.permissions);
      }
      setLoading(false);
    }
    fetchData();
  }, [roleId, location.state]);

  const getForms = async () => {
    const result = await apiCreateRole.request();
    if (result) {
      setForms(result);
      initializeCheckAll(result);
    }
  };

  const initializeCheckAll = (formsData) => {
    const initialCheckAllState = {};
    formsData.forEach((form) => {
      initialCheckAllState[form.header] = form.fields.every((field) =>
        permissions.some((p) => p.id === field.id)
      );
    });
    setCheckAll(initialCheckAllState);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const selectedPermissionIds = permissions.map((p) => p.id);
    const result = await apiStoreRole.request({
      name: roleName,
      permissions: selectedPermissionIds,
    });
    setLoading(false);
    if (result) {
      setDialogProps({ isOpen: true });
    }
  };

  const handleCheckAllChange = (formHeader, fields, isChecked) => {
    const updatedPermissions = isChecked
      ? [...permissions, ...fields.filter((field) => !permissions.some((p) => p.id === field.id))]
      : permissions.filter((p) => !fields.some((field) => field.id === p.id));

    setPermissions(updatedPermissions);

    const updatedCheckAll = { ...checkAll, [formHeader]: isChecked };
    setCheckAll(updatedCheckAll);
  };

  const handleCheckboxChange = (field) => {
    const isChecked = permissions.some((p) => p.id === field.id);
    let updatedPermissions;
    if (isChecked) {
      updatedPermissions = permissions.filter((p) => p.id !== field.id);
    } else {
      updatedPermissions = [...permissions, field];
    }
    setPermissions(updatedPermissions);

    // Update checkAll state for the corresponding form header
    const formHeader = forms.find(form => form.fields.some(f => f.id === field.id)).header;
    const allFields = forms.find(form => form.header === formHeader).fields;
    const isAllChecked = allFields.every(f => updatedPermissions.some(p => p.id === f.id));
    const updatedCheckAll = { ...checkAll, [formHeader]: isAllChecked };
    setCheckAll(updatedCheckAll);
  };

  return (
    <>
    <Card sx={{ mb: 3 }}>
      <CardHeader title="Add Role" />
      <CardContent>
        <AppTextInput
          label="Role Name"
          value={roleName}
          required={true}
          onChange={(e) => setRoleName(e.target.value)}
        />
      </CardContent>
    </Card>
    {forms.map((form, index) => (
        <div className="jr-card" key={index}>
          <h2>{form.header}</h2>
          <FormControlLabel
            checked={!!checkAll[form.header]}
            onChange={(e) => handleCheckAllChange(form.header, form.fields, e.target.checked)}
            control={<Checkbox />}
            label={`Check All ${form.header} Permissions`}
          />
          <div className="d-flex flex-wrap">
            {form.fields.map((field) => (
              <AppCheckbox
                key={field.id}
                label={field.name}
                value={permissions.some((p) => p.id === field.id)}
                onChange={() => handleCheckboxChange(field)}
              />
            ))}
          </div>
        </div>
      ))}

      <div className="jr-card d-flex justify-content-center w-100 mt-5">
        {roleId && (
          <AppButton
            className="mr-2"
            text="Cancel"
            variant="outlined"
            onClick={() => navigate(-1)}
          />
        )}
        <AppButton text="Submit" onClick={handleSubmit} loading={loading} />
      </div>

      {dialogProps.isOpen && (
        <MessageDialog
          title="Done successfully"
          onConfirm={() => {
            setDialogProps({ isOpen: false });
            navigate('/role/list');
          }}
        />
      )}
    </>
  );
};

export default AddRole;
