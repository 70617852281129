export const backgroundColor = "#1D222B";
export const primaryColor = "#41adce";
export const secondaryColor = "#2892ff";
export const sidebarColor = "#0B111D";
export const borderColor = "#515763";
export const paperColor = "#272F3A";
export const inputColor = "#14181E";
export const textColor = "#5E6D7F";
export const errorColor = "#e84118";
export const successColor = "#18A558";
export const warningColor = "#FF9B50";
export const whiteColor = "#FFFFFF";
export const blackColor = "#000000";
export const grayColor = "#505354";
