import React, { useEffect, useState } from "react";
import CardHeader from "components/CardHeader";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserFeesListAction } from "redux/actions/usersActions";
import UserFee from "./UserFee";

const UserFees = () => {
  const dispatch = useDispatch();
  const { id: userId } = useParams();
  const [fees, setFees] = useState([]);

  useEffect(() => {
    if (userId) dispatch(getUserFeesListAction(userId, setFees));
  }, [userId]);

  // useEffect(() => {
  //   if (fees) {
  //     debugger;
  //   }
  // }, [fees]);
  return (
    <div className="jr-card jr-full-card">
      <CardHeader title="Fees and Limits" />
      <div style={{ padding: "24px", marginBottom: "12px" }}>
        {fees.length
          ? fees.map((fee) => {
              return <UserFee data={fee} />;
            })
          : ""}
      </div>
    </div>
  );
};

export default UserFees;
