import React, { useEffect, useState } from "react";
import AppTextInput from "components/AppTextInput";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { downloadFileAPI } from "services/API/fileManager";
import { Modal, ModalContent } from "components/Images/ModalImage";

const UserNationality = ({ data }) => {
  const basicInfo = data;
  const [values, setValues] = useState({});
  const [images, setImages] = useState(null);
  const [isOpen, setIsopen] = useState(false);
  const showModal = () => setIsopen((prev) => !prev);

  useEffect(() => {
    if (basicInfo) {
      setValues({
        monthlyIncome: basicInfo.monthlyIncome,
        incomeSource: basicInfo.incomeSource,
        nationalId: basicInfo.nationalId,
        nationality: basicInfo.nationality,
        occupation: basicInfo.occupation,
        selfie: basicInfo.selfie,
      });
    }
  }, [basicInfo]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  async function showImage() {
    try {
      let response = await downloadFileAPI({ fileAddress: values.selfie });
      let base64Data = `data:${response.data.type};base64,${response.data.value}`;
      if (response.status === "success") {
        setImages(base64Data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    if (values.selfie) {
      showImage();
    }
  }, [values]);

  return (
    <Card>
      <CardHeader title="National Info" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <AppTextInput
              label="Monthly Income"
              value={values.monthlyIncome}
              onChange={handleChange("monthlyIncome")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label="Income Source"
              value={values.incomeSource}
              onChange={handleChange("incomeSource")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label="National Id"
              value={values.nationalId}
              onChange={handleChange("nationalId")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label="Nationality"
              value={values.nationality}
              onChange={handleChange("nationalityId")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label="Occupation Id"
              value={values.occupation}
              onChange={handleChange("occupationId")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default UserNationality;
