import axiosMiddleware from "./axiosMiddleware";

export const getAdminWalletAPI = (adminId) => {
  let postData = {
    admin_id: adminId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/admin/wallet",
    data: postData,
  });
};

export const getAdminFeesListAPI = (adminId) => {
  return axiosMiddleware({
    method: "post",
    url: "/admin/fees",
    data: {
      adminId,
    },
  });
};

export const getAdminInfoAPI = ({ adminId }) => {
  let postData = {
    adminId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/admin/info",
    data: postData,
  });
};

export const getAdminBalanceAPI = (walletId) => {
  let postData = {
    wallet_id: walletId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/admin/get-balance",
    data: postData,
  });
};

export const getSuperAdminBalanceAPI = (walletId) => {
  let postData = {
    wallet_id: walletId,
  };
  return axiosMiddleware({
    method: "post",
    url: "/admin/get-admin-balance",
    data: postData,
  });
};

export const editAdminWalletMinimumBalanceAPI = ({ walletId, minBalance }) => {
  let postData = {
    walletId,
    minBalance,
  };
  return axiosMiddleware({
    method: "post",
    url: "/admin/wallet/edit",
    data: postData,
  });
};

export const getAdminsListAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "/all-users",
  });
};
