import {
  FORGOT_PASSWORD_FIRST_STEP_FAILURE,
  FORGOT_PASSWORD_FIRST_STEP_STARTED,
  FORGOT_PASSWORD_FIRST_STEP_SUCCESS,
  FORGOT_PASSWORD_SECOND_STEP_FAILURE,
  FORGOT_PASSWORD_SECOND_STEP_STARTED,
  FORGOT_PASSWORD_SECOND_STEP_SUCCESS,
  FORGOT_PASSWORD_THIRD_STEP_FAILURE,
  FORGOT_PASSWORD_THIRD_STEP_STARTED,
  FORGOT_PASSWORD_THIRD_STEP_SUCCESS,
  LOGIN_DEFAULT,
  LOGIN_FAILURE,
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_STARTED,
  VERIFY_SUCCESS,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: LOGIN_DEFAULT,
  token: "",
  otp: false,
  user: {
    permissions: [],
  },

  kyc: [],
  countries: [],
  states: [],
  cities: [],
  occupations: [],
  error: "",
};
export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_STARTED:
    case VERIFY_STARTED:
    case LOGOUT_STARTED:
    case FORGOT_PASSWORD_FIRST_STEP_STARTED:
    case FORGOT_PASSWORD_SECOND_STEP_STARTED:
    case FORGOT_PASSWORD_THIRD_STEP_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case LOGIN_SUCCESS:
    case FORGOT_PASSWORD_FIRST_STEP_SUCCESS:
    case FORGOT_PASSWORD_SECOND_STEP_SUCCESS:
    case FORGOT_PASSWORD_THIRD_STEP_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        rType: VERIFY_SUCCESS,
        token: action.payload.token,
        user: action.payload.user,
        error: "",
      };
    case LOGOUT_SUCCESS:
      return initialState;
    case LOGIN_FAILURE:
    case VERIFY_FAILURE:
    case LOGOUT_FAILURE:
    case FORGOT_PASSWORD_FIRST_STEP_FAILURE:
    case FORGOT_PASSWORD_SECOND_STEP_FAILURE:
    case FORGOT_PASSWORD_THIRD_STEP_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
