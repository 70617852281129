import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_PREMIUM_USERS } from "config/apiRoutes";
import { getPremiumUsersRequestAction } from "redux/actions/customerActions";
import CustomTable from "components/CustomTable";
import PaginationTable from "components/PaginationTable";
// --------------------------------------------------------------------------------
const PremiumUsers = () => {
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        header: "User's Full Name",
        size: 150,
        Cell: ({ cell, row }) => (
          <Link to={`/users/pending-users/${row.original.id}`} target="_blank">
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "agent",
        header: "Agent",
        size: 150,
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        size: 200,
        Cell: ({ cell, row }) => (
          <Link to={`/app/profile/${row.original.id}/Summary`}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorKey: "country_code",
        header: "Mobile Country Code",
        size: 150,
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
        size: 150,
      },
      {
        accessorKey: "kycStatus",
        header: "KYC Status",
        size: 150,
      },
      {
        accessorKey: "status",
        header: "User Status",
        size: 150,
      },
      {
        accessorKey: "pepId",
        header: "PeP ID",
        size: 150,
      },
      {
        accessorKey: "pepStatus",
        header: "PeP Status",
        size: 150,
      },
      {
        accessorKey: "premiumRequest",
        header: "Premium Request",
        size: 150,
      },
      {
        accessorKey: "registerTime",
        header: "Register Time",
        size: 150,
      },
    ],
    []
  );

  return (
    <PaginationTable
      title="Premium Users"
      columns={columns}
      apiEndpoint={GET_PREMIUM_USERS}
      keywordSearch
    />
  );
};

export default PremiumUsers;
