import { CardContent, Grid } from "@mui/material";
import KycField from "./KycField";
import { useState } from "react";
import CardMedia from "./Document";

const KycSection = ({ item, userStatus }) => {
  const [editable, setEditable] = useState(true);

  return (
    <CardContent>
      <Grid container sx={{ justifyContent: "space-between" }}>
        <Grid xs={12} lg={4}>
          {item?.fields?.map((field, index) => (
            <KycField key={index} field={field} editable={editable} />
          ))}
        </Grid>
        <Grid xs={12} lg={6} item sx={{ order: { xs: 0, lg: 1 } }}>
          {item?.documentFile?.value ? (
            <CardMedia fileAddress={item?.documentFile} />
          ) : null}
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default KycSection;
