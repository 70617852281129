import {
  GET_EXCHANGE_RATE_LIST_FAILURE,
  GET_EXCHANGE_RATE_LIST_STARTED,
  GET_EXCHANGE_RATE_LIST_SUCCESS,
  GET_TOP_UP_BANKS_FAILURE,
  GET_TOP_UP_BANKS_STARTED,
  GET_TOP_UP_BANKS_SUCCESS,
  GET_TOP_UP_COUNTRIES_FAILURE,
  GET_TOP_UP_COUNTRIES_STARTED,
  GET_TOP_UP_COUNTRIES_SUCCESS,
  GET_TOP_UP_IBAN_LIST_FAILURE,
  GET_TOP_UP_IBAN_LIST_STARTED,
  GET_TOP_UP_IBAN_LIST_SUCCESS,
  GET_TOP_UP_LIST_FAILURE,
  GET_TOP_UP_LIST_STARTED,
  GET_TOP_UP_LIST_SUCCESS,
  LOGIN_DEFAULT,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: LOGIN_DEFAULT,
  exchangeRateList: [],
  error: "",
};
export const ExchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXCHANGE_RATE_LIST_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_EXCHANGE_RATE_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_EXCHANGE_RATE_LIST_SUCCESS,
        exchangeRateList: action.payload.exchangeRateList,
        error: "",
      };
    case GET_EXCHANGE_RATE_LIST_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
