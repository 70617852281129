import axiosMiddleware from "./axiosMiddleware";

export const getCountriesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/general/get-country",
  });
};
export const getStatesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/general/get-state",
  });
};
export const getCitiesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/general/get-city",
  });
};
export const getOccuoationAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/general/get-occupation",
  });
};
export const getEventsAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/general/get-event",
  });
};
export const getTimeZonesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/general/get-timezones",
  });
};
export const getBadgesAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "/user/get-badges",
  });
};
export const calculateFeeAPI = ({ userId, eventId, walletId }) => {
  let postData = {
    userId,
    eventId,
    walletId,
  };
  return axiosMiddleware({
    method: "post",
    url: "fee/calculate",
    data: postData,
  });
};

export const calculateAdminFeeAPI = ({ adminId, eventId, walletId }) => {
  let postData = {
    adminId,
    eventId,
    walletId,
  };
  return axiosMiddleware({
    method: "post",
    url: "fee/admin/calculate",
    data: postData,
  });
};
