import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  createUserDefaultWalletRequestAction,
  getCurrenciesRequestAction,
  getUsersRequestAction,
  topUpRequestAction,
} from "redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "components/Autocomplete";
import DropDown from "components/DropDown";
import { getUserDefaultsRequestAction } from "./../../../../../redux/actions/usersActions";
import FeesCard from "./FeesCard";
import {
  deleteDefaultWalletRequestAction,
  getAgentsListRequestAction,
} from "redux/actions/agentActions";
import { showMessage } from "components/Modal";
import { LoadingButton } from "@mui/lab";
// --------------------------------------------------------------------------------
const UserDefaults = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { agentsList, userDefaults, currencies } = useSelector((state) => ({
    userDefaults: state.customer.userDefaults,
    agentsList: state.agent.agentsList,
    currencies: state.currency.currenciesList,
  }));

  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [agentId, setAgentId] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getUserDefaultsRequestAction());
    dispatch(getAgentsListRequestAction());
    dispatch(getCurrenciesRequestAction());
  }, []);

  return (
    <Card>
      <CardHeader title="Default Wallets" />
      <CardContent>
        <Grid container spacing={3}>
          {userDefaults?.map((wallet) => (
            <Grid item xs={12} md={6} lg={4}>
              <FeesCard wallet={wallet} currencies={currencies} />
            </Grid>
          ))}
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={currencies.map((currency) => currency.code)}
                      getOptionLabel={(option) => option}
                      //   defaultValue={[top100Films[13]]}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField {...params} label="Wallet" />
                      )}
                      value={selectedCurrencies}
                      onChange={(e, c) => setSelectedCurrencies(c)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropDown
                      label="Agent"
                      schema={{ label: "name", value: "id" }}
                      items={agentsList}
                      value={agentId}
                      onChange={(event) => setAgentId(event.target.value)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  loading={loading}
                  onClick={() =>
                    dispatch(
                      createUserDefaultWalletRequestAction({
                        agentId,
                        currencies: selectedCurrencies,
                        setLoading,
                        onSuccess: () => {
                          dispatch(getUserDefaultsRequestAction());
                          setSelectedCurrencies([]);
                          setAgentId("");
                          showMessage({
                            status: "success",
                          });
                        },
                      })
                    )
                  }
                >
                  Add Wallet
                </LoadingButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserDefaults;
