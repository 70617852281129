import React, { useEffect, useState } from "react";
import AppTextInput from "components/AppTextInput";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { downloadFileAPI } from "services/API/fileManager";
import { Modal, ModalContent } from "components/Images/ModalImage";

const UserPersonal = ({ data, setData }) => {
  const basicInfo = data;
  const [values, setValues] = useState({});
  const [images, setImages] = useState(null);
  const [isOpen, setIsopen] = useState(false);
  const showModal = () => setIsopen((prev) => !prev);

  useEffect(() => {
    if (basicInfo) {
      setValues({
        firstName: basicInfo.firstName,
        lastName: basicInfo.lastName,
        middleName: basicInfo.middleName,
        fatherName: basicInfo.fatherName,
        motherName: basicInfo.motherName,
        email: basicInfo.email,
        identityProof: basicInfo.identityProof,
        mobile: basicInfo.mobile,
      });
    }
  }, [basicInfo]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setData({ ...values, [prop]: event.target.value });
  };
  async function showImage() {
    try {
      let response = await downloadFileAPI({
        fileAddress: values.identityProof,
      });
      let base64Data = `data:${response.data.type};base64,${response.data.value}`;
      if (response.status === "success") {
        setImages(base64Data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    if (values.identityProof) {
      showImage();
    }
  }, [values]);

  return (
    <Card>
      <CardHeader title="Personal Info" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <AppTextInput
              label="First Name"
              value={values.firstName}
              onChange={handleChange("firstName")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label="Last Name"
              value={values.lastName}
              onChange={handleChange("lastName")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label="Middle Name"
              value={values.middleName}
              onChange={handleChange("middleName")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label="Father Name"
              value={values.fatherName}
              onChange={handleChange("fatherName")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label="Mother Name"
              value={values.motherName}
              onChange={handleChange("motherName")}
              // disabled={basicInfo.deleted_at != null ? true : false}
              dir="ltr"
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label={"Email "}
              value={values.email}
              onChange={handleChange("email")}
              dir="ltr"
              // disabled={basicInfo.deleted_at != null ? true : false}
            />
          </Grid>
          <Grid item xs={4}>
            <AppTextInput
              label="Mobile"
              value={values.mobile}
              onChange={handleChange("mobile")}
              dir="ltr"
              // disabled={basicInfo.deleted_at != null ? true : false}
            />
          </Grid>
        </Grid>
        {isOpen && (
          <ModalContent onClose={() => setIsopen(false)}>
            <img src={images} alt="" style={{ width: "90%" }} loading="eager" />
          </ModalContent>
        )}
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default UserPersonal;
