import React, { Component } from "react";
import ModalManager from "./ModalManager";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "components/Button";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import Typography from "@mui/material/Typography";
import {
  errorColor,
  successColor,
  warningColor,
  whiteColor,
} from "assets/colors";

export default class Modal extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      style: {},
      description: null,
      color: null,
      // duration: 3000,
      loading: false,
    };
    this.showMessage = this.showMessage.bind(this);
    this.closeMessage = this.closeMessage.bind(this);
    this.showCalled = true;
    if (!this._id) this._id = srid();
  }

  componentDidMount() {
    ModalManager.register(this);
  }
  componentWillUnmount() {
    ModalManager.unregister(this);
  }

  showMessage(config) {
    this.setState({ open: true, ...config });
  }

  closeMessage() {
    this.setState({
      open: false,
      description: null,
      onSubmit: null,
    });
  }

  render() {
    const {
      open,
      title,
      description,
      onSubmit,
      loading,
      style,
      status,
      duration,
      className,
      confirmBtnText = "Ok",
      cancelBtnText,
      onCancel,
    } = this.state;
    let defaultDescription = description
      ? description
      : statusDescription[status];

    return (
      <Dialog
        open={open}
        onClose={this.closeMessage}
        sx={{
          ".MuiDialogContent-root": {
            padding: 0,
          },
        }}
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <Box
            sx={{
              padding: 1,
              backgroundColor: statusColors[status],
            }}
          >
            {statusIcons[status]}
          </Box>
          <Box sx={{ pt: 3, pb: 2 }}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Box
            sx={{
              px: 3,
              mb: 2,
              display: defaultDescription ? "block" : "none",
            }}
          >
            <Typography variant="p">{defaultDescription}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={6}>
          {status === "warning" && (
            <Button color={status} variant="outlined" onClick={onCancel}>
              {cancelBtnText}
            </Button>
          )}
          <Button
            color={status}
            text={confirmBtnText}
            onClick={() => {
              if (onSubmit) {
                onSubmit((loading) => {
                  this.setState({ loading });
                });
              }
              this.closeMessage();
            }}
            style={{ maxWidth: 200, margin: "auto", width: "fit-content" }}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export function showMessage(...args) {
  const ref = ModalManager.getDefault();
  if (!!ref) {
    ref.showMessage(...args);
  }
}

export function closeMessage(...args) {
  const ref = ModalManager.getDefault();
  if (!!ref) {
    ref.closeMessage();
  }
}

function srid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4()}-${s4()}-${s4()}`;
}

const styles = {
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    zIndex: 1000,
  },
  text: {
    fontFamily: "IRANYekanMobileFaNum",
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "right",
    writingDirection: "rtl",
    lineHeight: 25,
    paddingHorizontal: 20,
  },
};

const statusIcons = {
  success: <TaskAltIcon sx={{ color: whiteColor, width: 40, height: 40 }} />,
  error: <ErrorOutlineIcon sx={{ color: whiteColor, width: 40, height: 40 }} />,
  warning: (
    <WarningAmberIcon sx={{ color: whiteColor, width: 40, height: 40 }} />
  ),
};

const statusColors = {
  success: successColor,
  error: errorColor,
  warning: warningColor,
};

const statusDescription = {
  success: "Done Successfully.",
  error: "Something went wrong. Try again!",
};
