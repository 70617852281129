import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "components/CustomTable";
import {
  getAgentsListRequestAction,
  getAgentTransactionsRequestAction,
} from "redux/actions/agentActions";
import { Typography } from "@mui/material";
import { errorColor, successColor } from "assets/colors";
// --------------------------------------------------------------------------------
const AgentTransactions = ({ data }) => {
  const dispatch = useDispatch();

  const { transactions } = useSelector((state) => ({
    transactions: state.agent.transactions,
  }));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  useEffect(() => {
    dispatch(
      getAgentTransactionsRequestAction({
        limit: pagination.pageSize,
        offset: pagination.pageIndex + 1,
      })
    );
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "event",
        header: "Event",
      },
      {
        accessorKey: "amount",
        header: "Amount",
        Cell: ({ row }) => {
          let amount = row.original.amount;
          let symbol = row.original.type === "increase" ? "+" : "-";
          let color =
            row.original.type === "increase" ? successColor : errorColor;
          return <Typography sx={{ color }}>{`${symbol}${amount}`}</Typography>;
        },
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "wallet",
        header: "Wallet",
      },
    ],
    []
  );

  return (
    <CustomTable
      label="Transactions"
      data={transactions.records}
      columns={columns}
      manualPagination={false}
    />
  );
};

export default AgentTransactions;
