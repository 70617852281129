// import moment from "moment-jalaali";

export const Currencies = {
  1: "USD",
  2: "EUR",
  3: "IRR",
  4: "CHF",
  5: "CAD",
  6: "AUD",
  7: "AED",
  8: "AZN",
  9: "CNY",
  10: "GBP",
  11: "INR",
  12: "IQD",
  13: "JPY",
  14: "KRW",
  15: "NOK",
  16: "RUB",
  17: "SEK",
  18: "TRY",
  19: "EIRR",
  20: "BTC",
  21: "ETH",
  22: "USDT",
};
export const Currencies1 = [
  { value: 1, label: "USD" },
  { value: 2, label: "EUR" },
  { value: 3, label: "IRR" },
  { value: 4, label: "CHF" },
  { value: 5, label: "CAD" },
  { value: 6, label: "AUD" },
  { value: 7, label: "AED" },
  { value: 8, label: "AZN" },
  { value: 9, label: "CNY" },
  { value: 10, label: "GBP" },
  { value: 11, label: "INR" },
  { value: 12, label: "IQD" },
  { value: 13, label: "JPY" },
  { value: 14, label: "KRW" },
  { value: 15, label: "NOK" },
  { value: 16, label: "RUB" },
  { value: 17, label: "SEK" },
  { value: 18, label: "TRY" },
  { value: 19, label: "EIRR" },
  { value: 20, label: "BTC" },
  { value: 21, label: "ETH" },
  { value: 22, label: "USDT" },
];

export const Events = {
  1: "Topup",
  2: "Exchange",
  3: "Gateway Payment",
  4: "Refund",
  5: "Payout",
  6: "E-check",
  7: "Transfer",
  8: "Commission",
  9: "Yekpay Link",
  10: "Wallet Payment",
  11: "Subscription",
  12: "Personal Gateway",
  13: "Plugin",
  14: "Yekpay",
};
export const HTransaction = {
  1: "Topup",
  3: "Gateway Payment",
  9: "Yekpay Link",
  12: "Personal Gateway",
};

export const Flags = {
  1: "us",
  2: "eu",
  3: "ir",
  4: "ch",
  5: "ca",
  6: "au",
  7: "ae",
  8: "az",
  9: "cn",
  10: "gb",
  11: "in",
  12: "iq",
  13: "jp",
  14: "kr",
  15: "no",
  16: "ru",
  17: "se",
  18: "tr",
  19: "ir",
};

export const CurrencyFlags = {
  EUR: require("assets/images/flags/european-union.png"),
  IRR: require("assets/images/flags/iran.png"),
  CHF: require("assets/images/flags/switzerland.png"),
  AED: require("assets/images/flags/united-arab-emirates.png"),
  CNY: require("assets/images/flags/china.png"),
  GBP: require("assets/images/flags/united-kingdom.png"),
  JPY: require("assets/images/flags/japan.png"),
  RUB: require("assets/images/flags/russia.png"),
  TRY: require("assets/images/flags/turkey.png"),
  BTC: require("assets/images/flags/bitcoin.png"),
  ETH: require("assets/images/flags/ethereum.png"),
  USDT: require("assets/images/flags/tether.png"),
  CAD: require("assets/images/flags/canada.png"),
  USD: require("assets/images/flags/united-states.png"),
  AUD: require("assets/images/flags/australia.png"),
  SEK: require("assets/images/flags/SEK.png"),
  KRW: require("assets/images/flags/KRW.png"),
  IQD: require("assets/images/flags/iraq.png"),
  INR: require("assets/images/flags/india.png"),
  AZN: require("assets/images/flags/azerbaijan.png"),
  NOK: require("assets/images/flags/NOK.png"),
  NZD: require("assets/images/flags/NZD.png"),
  SGD: require("assets/images/flags/singapore.png"),
  DKK: require("assets/images/flags/DKK.png"),
  PHP: require("assets/images/flags/philippines.png"),
  HKD: require("assets/images/flags/hong-kong.png"),
};
export const firstAssignee = {
  1: "MehrdadParsa",
  2: "AmirBarkhordari",
  4: "MiraliHosseini",
  5: "PooyaSabramooz",
  6: "NaserHosseini",
  7: "MonaAbbasi",
  8: "HastiDolatkhah",
  11: "AhmadMozafar abadi",
  13: "NavidSabetifard",
  15: "AliGharaei",
  16: "MohammadKhadishi",
  18: "ArdeshirAhvaz",
  19: "ShimaFeizzadeh",
  25: "MostafaHejazi",
  26: "MarziyeSafa",
  31: "EdaAtes",
  32: "KameliyaJahandide",
  33: "SaraFarsi",
  34: "Mohammad HoseinKafrashian",
};

export const Language = {
  en: "English",
  fa: "Persian",
};
export const Plan = {
  1: "Personal",
  2: "Business",
  3: "Business Plus",
};
export const Main_Status = {
  Pending: "Pending",
  Approved: "Approved",
  Declined: "Declined",
};
export const First_Status = { Active: "Active", Inactive: "Inactive" };
export const First_Status2 = {
  Active: "Active",
  Inactive: "Inactive",
  Suspend: "Suspend",
};
export const Secound_Status = {
  Pending: "Pending",
  Approved: "Approved",
  Declined: "Declined",
  Suspend: "Suspend",
};
export const Third_Status = {
  Approved: "Approved",
  Declined: "Declined",
  Pending: "Pending",
  Fraud: "Fraud",
  "Under Review": "Under Review",
};
export const forth_Status = {
  Approved: "Approved",
  Declined: "Declined",
  Pending: "Pending",
  Suspend: "Suspend",
  Trial: "Trial",
  Canceled: "Canceled",
};
export const Main_Stage = {
  Submitted: "Submitted",
  Declined: "Declined",
  "Implementing By Customer": "Implementing By Customer",
  "Implementing By Yekpay": "Implementing By Yekpay",
  "Requesting Docs": "Requesting Docs",
  "Invalid Documents": "Invalid Documents",
  Contract: "Contract",
  Finalized: "Finalized",
  "Cancel By Customer": "Cancel By Customer",
  "Not Accessible": "Not Accessible",
};
// fromAll = moment().utcOffset(0);
// fromAll.set({ year: 2016, hour: 0, minute: 0, second: 0, millisecond: 0 });
// fromAll.toISOString();
// fromAll.format();
// export var fromAll;

// fromPrevSixMonth = moment().subtract(6, "months").utcOffset(0);
// fromPrevSixMonth.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
// fromPrevSixMonth.toISOString();
// fromPrevSixMonth.format();
// export var fromPrevSixMonth;

// fromPrevMonth = moment().subtract(1, "months").utcOffset(0);
// fromPrevMonth.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
// fromPrevMonth.toISOString();
// fromPrevMonth.format();
// export var fromPrevMonth;

// export const calculateNextYear = (now) => {
//   let nextYear = moment(now);
//   nextYear.add(1, "year");
//   nextYear.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
//   nextYear.toISOString();
//   nextYear.format("YYYY/MM/DD");

//   return nextYear;
// };

// fromPrevWeek = moment().subtract(1, "weeks").utcOffset(0);
// fromPrevWeek.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
// fromPrevWeek.toISOString();
// fromPrevWeek.format();
// export var fromPrevWeek;

// from = moment().utcOffset(0);
// from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
// from.toISOString();
// from.format();
// export var from;

// var to = moment().utcOffset(0);
// to.set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
// to.toISOString();
// to.format();
// export var to;

// API

export const BaseURL = `${process.env.REACT_APP_API_URL}/api/`;
export const ApiURL = "https://api.yekpay.com/";
export const StorageBaseURL = "https://api.finotransfer.com/storage/";
export const token = {
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
};
export const tokenWithUpload = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "content-type": "multipart/form-data",
  },
};

export const boot = JSON.parse(localStorage.getItem("boot")) ?? {};

// API List

// -- Boot
export const API_GET_BOOT = BaseURL + "boot";

// -- Login
export const API_GET_SIGN_IN = BaseURL + "login";
export const API_GET_LOGIN_API_ENDPOINT = BaseURL + "verify";
export const API_GET_LOGOUT_API_ENDPOINT = BaseURL + "logout";

// -- IBAN
export const API_GET_IBAN = BaseURL + "tools/iban-inquiry";

// -- Card
export const API_GET_CARD_INQUIRY = BaseURL + "tools/card-inquiry";
// --Shahkar
export const API_GET_SHAHKAR_INQUIRY = BaseURL + "tools/shahkar";

// --Postal code
export const API_GET_POSTAL_CODE_INQUIRY =
  BaseURL + "tools/postal-code-inquiry";
// -- Users
export const API_GET_ALL_USERS = BaseURL + "users";
export const API_GET_ALL_BUSINESS_USERS = BaseURL + "user/business";
export const API_GET_USER = BaseURL + "user";
export const API_GET_USER_BLOCKED = BaseURL + "user/blocked";
export const API_GET_USER_FRAUD_SUSPECTED = BaseURL + "user/fraud-suspect";

// -- Plans
export const API_GET_PLANS = BaseURL + "user-plan/user";
export const API_CREATE_PLANS = BaseURL + "user-plan/create";

// -- Annoncements
export const API_GET_ANNOUNCEMENT = BaseURL + "announcement/all";
export const API_UPDATE_ANNOUNCEMENT = BaseURL + "announcement/update";
export const API_CREATE_ANNOUNCEMENT = BaseURL + "announcement/create";
export const API_DELETE_ANNOUNCEMENT = BaseURL + "announcement/delete";
export const API_SHOW_ANNOUNCEMENT = BaseURL + "announcement/show";
export const API_CHANGE_STATUS_ANNOUNCEMENT =
  BaseURL + "announcement/change-status";
//--identity errors
export const API_IDENTITY_CARD_ERRORS = BaseURL + "user/identity-card-errors";
// -- Change Status
export const API_CHANGE_STATUS = BaseURL + "user/change-status";
export const API_USER_EDIT = BaseURL + "user/edit";

// -- Billing
export const API_GET_BILLING = BaseURL + "user-address/user";
export const API_CHANGE_STATUS_BILLING = BaseURL + "user-address/change-status";
export const API_GET_ADDRESS_ERRORS = BaseURL + "user/address-errors";
//youtube
export const API_GET_YOUTUBE_CHANNEL_ALL = BaseURL + "youtube-channels/reports";
export const API_GET_YOUTUBE_ARZISHO_FORM = BaseURL + "youtube/arzisho-form";
export const API_GET_YOUTUBE_CHANNELS = BaseURL + "user-youtube-channels/all";
export const API_GET_YOUTUBE_PAYOUTS = BaseURL + "youtube-channels/payouts";

export const API_EDIT_YOUTUBE_CHANNELS_INFO =
  BaseURL + "user-youtube-channels/edit-information";
export const API_EDIT_YOUTUBE_CHANNELS_CONTRACT =
  BaseURL + "user-youtube-channels/edit-contract";

export const API_ADD_YOUTUBE_CHANNELS =
  BaseURL + "user-youtube-channels/create";

export const API_ADD_YOUTUBE_TRANSACTION =
  BaseURL + "user-youtube-channels/add-transaction";
export const API_PAYOUT_YOUTUBE = BaseURL + "user-youtube-channels/add-payout";
// -- Transaction
export const API_GET_TRANSACTION = BaseURL + "transaction/user";
export const API_GET_TRANSACTION_refund =
  BaseURL + "transaction/refund/request";
export const API_GET_TRANSACTION_FAIL = BaseURL + "transaction/user/failed";
export const API_GET_TRANSACTION_SHOW = BaseURL + "transaction/show";
export const API_GET_TRANSACTIONS = BaseURL + "transaction/all";
export const API_GET_HOLD_TRANSACTION = BaseURL + "transaction/hold";
export const API_GET_WIRE_PAYOUT = BaseURL + "payout/offline";
export const API_GET_BITCOIN_TRANSACTIONS =
  BaseURL + "crypto-transactions/bitcoin/all";
export const API_GET_BITCOIN_WALLETS = BaseURL + "crypto-wallet/bitcoin/all";

export const API_CHANGE_STATUS_HOLD_TRANSACTION =
  BaseURL + "transaction/hold/change-status";
export const API_GET_REFUND_REQUEST = BaseURL + "transaction/refund";
export const API_CHANGE_STATUS_REFUND_REQUEST =
  BaseURL + "transaction/refund/change-status";
export const API_GET_TRANSFER = BaseURL + "transfers/all";
export const API_GET_EXCHANGE = BaseURL + "exchanges/all";
export const API_GET_PERSONAL_TRANSACTION =
  BaseURL + "personal-gateway-transactions/all";
export const API_GET_PAYMENT_LINK_TRANSACTION =
  BaseURL + "payment-link-transactions/all";
export const API_GET_WEBSITE_GATEWAY = BaseURL + "gateway-transactions/all";

// -- estchange trading
export const API_GET_ESTCHANGE_REQUEST =
  BaseURL + "estchange/trading/requests/all";
export const API_GET_ESTCHANGE_CREATE_REQUEST =
  BaseURL + "estchange/trading/requests/store";
export const API_GET_ESTCHANGE_BALANCE = BaseURL + "estchange/trading/balance";
export const API_GET_ESTCHANGE_RATE = BaseURL + "estchange/trading/rate";
export const API_GET_ESTCHANGE_TRADING_PING =
  BaseURL + "estchange/trading/ping";
export const API_GET_ESTCHANGE_TRADING_TIME =
  BaseURL + "estchange/trading/time";

//-- blockcypher
export const API_GET_BITCOIN_BLOCKCYPHER =
  BaseURL + "crypto/bitcoin/blockcypher";
export const API_GET_ETHEREUM_BLOCKCYPHER =
  BaseURL + "crypto/ethereum/blockcypher";

// --estchange billing
export const API_GET_ESTCHANGE_CLIENTS =
  BaseURL + "estchange/billing/clients/all";
export const API_GET_ESTCHANGE_BILLING_RATE =
  BaseURL + "estchange/billing/rate";
export const API_CREATE_ESTCHANGE_BILLING_REQUEST =
  BaseURL + "estchange/billing/clients/store";
export const API_GET_ESTCHANGE_BILLING_Rate =
  BaseURL + "estchange/billing/rate";
export const API_GET_ESTCHANGE_BILLING_PING =
  BaseURL + "estchange/billing/ping";
export const API_GET_ESTCHANGE_BILLING_TIME =
  BaseURL + "estchange/billing/time";

// Iran Kish
export const API_CREATE_ACCEPTOR = BaseURL + "irankish/acceptor/create";
export const API_GET_ACCEPTOR = BaseURL + "irankish/acceptor/all";
export const API_GET_BANK_ALL = BaseURL + "irankish/bank/all";
export const API_GET_ACTIVITY_ALL = BaseURL + "irankish/activity/all";
export const API_GET_BUSINESS_ALL = BaseURL + "irankish/business/all";
export const API_GET_ACTIVITY_ONE = BaseURL + "irankish/activity/get-one";
export const API_GET_PROVINCE_ALL = BaseURL + "irankish/province/all";
export const API_GET_COUNTRY_ALL = BaseURL + "irankish/country/all";
export const API_GET_ACCEPTOR_INQUIRY = BaseURL + "irankish/inquiry";
export const API_GET_ACCEPTOR_SEND = BaseURL + "irankish/send";
export const API_GET_ACCEPTOR_SEND_LOGO = BaseURL + "irankish/send-logo";
export const API_GET_CITY_ONE = BaseURL + "irankish/city/get-one";
export const API_GET_AREA = BaseURL + "irankish/area/all";
export const API_GET_ACCEPTOR_SHOW = BaseURL + "irankish/acceptor/show";
export const API_Edit_ACCEPTOR = BaseURL + "irankish/acceptor/edit";
export const API_GET_FACILITATOR_TRANSACTIONS =
  BaseURL + "payment-facilitator/transaction/all";
export const API_GET_FACILITATOR_DAILY =
  BaseURL + "payment-facilitator/daily/all";

// Yekpay Wallet
export const API_GET_YEKPAY_WALLET_TRANSACTION =
  BaseURL + "transaction/yekpay-wallet";
export const API_GET_YEKPAY_WALLET_BALANCE =
  BaseURL + "transaction/yekpay-wallet-balance";
export const API_CHANGE_YEKPAY_WALLET_SETTING =
  BaseURL + "transaction/yekpay-wallet-setting";
export const API_GET_YEKPAY_WALLET_PAYOUT =
  BaseURL + "transaction/yekpay-wallet/payout";
export const API_GET_YEKPAY_WALLET_PAYOUT_LIST =
  BaseURL + "transaction/yekpay-wallet/payout/list";
export const API_GET_YEKPAY_WALLET_PAYOUT_INQUIRY =
  BaseURL + "transaction/yekpay-wallet/payout/inquiry";
export const API_GET_YEKPAY_WALLET_GET_IBANS =
  BaseURL + "transaction/yekpay-wallet/get-ibans";
// -- Ballance
export const API_GET_BALANCE = BaseURL + "wallet/user";
export const API_CREATE_BALANCE = BaseURL + "wallet/create";
export const API_CREATE_ADMIN_BALANCE = BaseURL + "admin/wallet";
export const API_DELETE_BALANCE = BaseURL + "wallet/delete";
export const API_GET_BALANCE_MONEY = BaseURL + "balance";
export const API_GET_USERS_BALANCE = BaseURL + "report/finance/users-balance";

export const API_GET_ELASTIC_BALANCE = BaseURL + "elastic-balance";
export const API_GET_AYANDE_BALANCE = BaseURL + "ayande-balance";
export const API_GET_PARSIAN_BALANCE = BaseURL + "parsian-balance";
export const API_GET_KAVENEGAR_BALANCE = BaseURL + "kavehnegar-balance";

// -- Bank Accounts
export const API_GET_BANK_ACCOUNT = BaseURL + "bank-account/user";
export const API_CHANGE_STATUS_BANK_ACCOUNT =
  BaseURL + "bank-account/change-status";
export const API_DELETE_BANK_ACCOUNT = BaseURL + "bank-account/delete";
export const API_RESTORE_BANK_ACCOUNT = BaseURL + "bank-account/restore";
export const API_CREATE_BANK_ACCOUNT = BaseURL + "bank-account/create";
export const API_GET_BANK_ERRORS = BaseURL + "user/bank-account-errors";
export const API_CHANGE_BANK_ACCOUNT_STATUS =
  BaseURL + "user/change-bank-account-errors";

// -- Company Info
export const API_GET_COMPANY = BaseURL + "user-company/user";
export const API_GET_ALL_PENDING_COMPANY_INFO =
  BaseURL + "bank-account/all-pending";
export const API_CHANGE_STATUS_IDENTITY =
  BaseURL + "bank-account/change-status";
export const API_CHANGE_STATUS_COMPANY = BaseURL + "user-company/change-status";

// -- Comments
export const API_GET_COMMENTS = BaseURL + "user-comments/user";
export const API_DELETE_COMMENTS = BaseURL + "user-comments/delete";
export const API_CREATE_COMMENTS = BaseURL + "user-comments/create";

// -- Logs
export const API_GET_LOGS = BaseURL + "user-log/user";
export const API_GET_ALL_LOGS = BaseURL + "log/all";
export const API_USER_LOG = BaseURL + "user-change/user";

// -- Ticket
export const API_GET_TICKET = BaseURL + "ticket/user";
export const API_GET_ALL_TICKET = BaseURL + "ticket/all";
export const API_GET_OPEN_TICKET = BaseURL + "ticket/open";
export const API_GET_SHOW_TICKET = BaseURL + "ticket/show";
export const API_GET_REPLY_TICKET = BaseURL + "ticket/reply";
export const API_TICKET_CHANGE_ROLE = BaseURL + "ticket/change-role";
export const API_TICKET_CHANGE_STATUS = BaseURL + "ticket/change-status";
export const API_TICKET_ADD_COMMENT = BaseURL + "ticket/add-comment";
export const API_TICKET_REPORT = BaseURL + "ticket/report";
export const API_NEW_TICKET = BaseURL + "ticket/new-ticket";

// -- Setting
export const API_GET_ALL_SETTING = BaseURL + "setting/all";
export const API_UPDATE_ALL_SETTING = BaseURL + "setting/update";

// -- Yekpay Link
export const API_GET_YEKPAY_LINK = BaseURL + "yekpay-link/user";
export const API_GET_ALL_YEKPAY_LINK = BaseURL + "yekpay-link/all";
export const API_CHANGE_YEKPAY_LINK = BaseURL + "yekpay-link/change-status";
export const API_DELETE_YEKPAY_LINK = BaseURL + "yekpay-link/delete";

// -- Gateway
export const API_GET_GATEWAY_SHOW = BaseURL + "gateway/show";
export const API_GET_GATEWAY = BaseURL + "gateway/user";
export const API_GET_ALL_GATEWAY = BaseURL + "gateway/all";
export const API_CHANGE_GATEWAY = BaseURL + "gateway/change-status";
export const API_GATEWAY_EDIT = BaseURL + "gateway/edit";
export const API_GATEWAY_CHANGE_STATUS = BaseURL + "gateway/change-status";
export const API_GET_GATEWAY_IP_ADDRESS = BaseURL + "gateway-ip-address/show";
export const API_DELETE_GATEWAY_IP_ADDRESS =
  BaseURL + "gateway-ip-address/delete";
export const API_CREATE_GATEWAY_IP_ADDRESS =
  BaseURL + "gateway-ip-address/create";
export const API_GET_GATEWAY_CURRENCY = BaseURL + "gateway-currency/show";
export const API_DELETE_GATEWAY_CURRENCY = BaseURL + "gateway-currency/delete";
export const API_CREATE_GATEWAY_CURRENCY = BaseURL + "gateway-currency/create";
export const API_GET_GATEWAY_HISTORY = BaseURL + "gateway-status/show";
export const API_GET_GATEWAY_AGENT = BaseURL + "gateway-agent/show";
export const API_DELETE_GATEWAY_AGENT = BaseURL + "gateway-agent/delete";
export const API_CREATE_GATEWAY_AGENT = BaseURL + "gateway-agent/create";
export const API_GET_GATEWAY_REPORT = BaseURL + "report/gateway";
export const API_UPDATE_GATEWAY_DOCUMENT =
  BaseURL + "gateway-document/change-status";

// -- Dashboard
export const API_GET_DASHBOARD = BaseURL + "dashboard";

// -- ErrorCodes
export const API_GET_ERRORS = BaseURL + "error/all";
export const API_Update_ERRORS = BaseURL + "error/update";
export const API_CREATE_ERRORS = BaseURL + "error/create";
export const API_DELETE_ERRORS = BaseURL + "error/delete";

// -- Exchange Transactions
export const API_GET_EXCHANGE_TRANSACTIONS =
  BaseURL + "exchange-transactions/all";
export const API_UPDATE_EXCHANGE_TRANSACTIONS =
  BaseURL + "exchange-transactions/update";
export const API_CREATE_EXCHANGE_TRANSACTIONS =
  BaseURL + "exchange-transactions/create";
export const API_DELETE_EXCHANGE_TRANSACTIONS =
  BaseURL + "exchange-transactions/delete";
export const API_GET_TRANSACTION_FLOW =
  BaseURL + "report/finance/transaction-flow";
export const API_GET_TRANSACTIONS_BILLS =
  BaseURL + "report/finance/transactions-bills";
export const API_GET_TRANSACTIONS_BILLS2 =
  BaseURL + "report/finance/transactions-bills2";
export const API_GET_FINANCE_TOTAL = BaseURL + "report/finance/total";
export const API_GET_USER_FINANCE_TOTAL = BaseURL + "user-finance";

// -- Pending Items
export const API_GET_ALL_PENDING_BILLING = BaseURL + "user-address/all-pending";
export const API_GET_ALL_PENDING_BANK_ACCOUNT =
  BaseURL + "bank-account/all-pending";
export const API_GET_ALL_PENDING_COMPANY = BaseURL + "user-company/all-pending";
export const API_GET_ALL_PENDING_IDENTITY =
  BaseURL + "user-identity/all-pending";
export const API_GET_ALL_PENDING_GATEWAY_DOCUMENT =
  BaseURL + "gateway-document/all-pending";

// -- Card Requests
export const API_GET_CARD_REQUESTS = BaseURL + "credit-card/all";

// -- Search
export const API_GET_SEARCH = BaseURL + "search";
export const API_GET_SEARCH_ADMIN = BaseURL + "search-admins";

// -- Change Password
export const API_GET_CHANGE_PASSWORD = BaseURL + "change-password";

// -- Exchange Rate
export const API_GET_EXCHANGE_RATE = BaseURL + "exchange-rate/all";
export const API_UPDATE_EXCHANGE_RATE = BaseURL + "exchange-rate/update";
export const API_GET_EXCHANGE_RATE_DIAGRAM = BaseURL + "exchange-rate/diagram";

// -- Admin
export const API_GET_ALL_ADMIN = BaseURL + "all";
// export const API_UPDATE_ADMIN = BaseURL + "update";
// export const API_CREATE_ADMIN = BaseURL + "create";
// export const API_DELETE_ADMIN = BaseURL + "delete";
export const API_GET_ADMIN_BALANCE = BaseURL + "admin/get-balance";

// -- RollCall
export const API_GET_ROLLCALL = BaseURL + "rollcall/user";
export const API_UPDATE_ROLLCALL = BaseURL + "rollcall/edit";
export const API_UPLOAD_ROLLCALL = BaseURL + "rollcall/upload";
export const API_PENDING_ROLLCALL = BaseURL + "rollcall/pending/all";
export const API_APPROVE_ROLLCALL = BaseURL + "rollcall/approve";
export const API_GET_ROLLCALL_LIST = BaseURL + "rollcall/all";

// -- IPG Transaction
export const API_GET_ZIRAAT = BaseURL + "transaction/ziraat";
export const API_GET_PARSIAN = BaseURL + "transaction/parsian";
export const API_GET_VAKIF = BaseURL + "transaction/vakif";
export const API_GET_IYZICO = BaseURL + "transaction/iyzico";
export const API_GET_ESNEK = BaseURL + "transaction/esnek";
export const API_GET_PAYBYME = BaseURL + "transaction/paybyme";
export const API_GET_SIPAY = BaseURL + "transaction/sipay";
export const API_GET_WIRE = BaseURL + "transaction/wire";
export const API_GET_MOKA = BaseURL + "transaction/moka";
export const API_GET_IRANKISH = BaseURL + "transaction/irankish";
export const API_GET_SRIPE = BaseURL + "transaction/stripe";
export const API_GET_Akbank = BaseURL + "transaction/akbank";

// -- Plugins
export const API_GET_PLUGIN = BaseURL + "plugin/all";
export const API_UPDATE_PLUGIN = BaseURL + "plugin/update";

// -- Incomes
export const API_GET_INCOMES = BaseURL + "report/finance/incomes";

// -- Topup and payout
export const API_GET_TOPUP = BaseURL + "topup/all";
export const API_GET_PAYOUT = BaseURL + "payout/all";
export const API_GET_TOPUP_USER = BaseURL + "topup/show";
export const API_CHANGE_STATUS_TOPUP = BaseURL + "topup/change-status";

// -- Debts
export const API_GET_DEBTS = BaseURL + "report/finance/debts";

// -- Text Messages
export const API_GET_TEXT_MESSAGES = BaseURL + "text-message/all";
export const API_GET_TEXT_MESSAGES_USER = BaseURL + "text-message/user";
export const API_NEW_TEXT_MESSAGE = BaseURL + "send-sms-to-user";
export const API_GET_TEXT_MESSAGE_GROUP_LISTING = BaseURL + "get-group-list";
export const API_SEND_GROUP_SMS = BaseURL + "send-group-sms";
export const API_GET_CAMPAIGNS_LIST = BaseURL + "get-campaigns-list";
export const API_GET_SHOW_CAMPAIGNS_MESSAGES =
  BaseURL + "show-campaigns-messages";
export const API_NEW_TEXT_MESSAGE_BY_MOBILE_NUMBER =
  BaseURL + "send-single-sms";

export const API_GET_CREATE_PAYOUT = BaseURL + "payout/create";
export const API_GET_PAYOUT_USER = BaseURL + "payout/show";
export const API_CHANGE_STATUS_PAYOUT = BaseURL + "payout/change-status";

export const API_GET_SEND_MONEY = BaseURL + "transaction/send-money";
export const API_CREATE_SEND_MONEY = BaseURL + "transaction/send-money/create";

//Exchange Panel
export const API_GET_EXPANEL_ALL_USERS = BaseURL + "exchange-panel/get-users";
export const API_GET_EXPANEL_USER_TRANSACTIONS =
  BaseURL + "exchange-panel/user/transactions";
export const API_EXPANEL_USER_INFO = BaseURL + "exchange-panel/user/info";
export const API_EXPANEL_USER_BALANCE = BaseURL + "exchange-panel/user/balance";
export const API_GET_EXPANEL_USER_TRANSACTIONS_RECEIPT =
  BaseURL + "exchange-panel/transaction/receipt";

export const API_GET_TRANSACTIONS_PAYOUT_PAY =
  BaseURL + "transactions/payout/pay";

export const API_GET_ALL_ITSAAZ = BaseURL + "itsaz/get-all";
export const API_GET_USER_ITSAAZ = BaseURL + "itsaz/get-user";

export const API_PAYOUT_PENDING_LIST = BaseURL + "payout/pending-list";
export const API_PAYOUT_APPROVED_LIST = BaseURL + "payout/approved-list";
export const API_PAYOUT_REJECTED_LIST = BaseURL + "payout/rejected-list";
export const API_PAYOUT_REFUNDED_LIST = BaseURL + "payout/refunded-list";
export const API_PAYOUT_EXECUTIVE_LIST = BaseURL + "payout/executive-list";
export const API_ALL_TRANSACTIONS_LIST = BaseURL + "payout/all-list";
export const API_ALL_EXCHANGES_LIST = "/list-exchange";
export const API_ALL_TRANSFERS_LIST = "/list-transfer";

export const API_ALL_USERS = "users/list";
export const API_USER_CURRENCIES = "/get-user-currencies";
export const API_TOPUP_PAYIN = "topup/payin";
export const API_PAYOUT_PENDING_SHOW = "payout/pending-show";
export const API_PAYOUT_CONFIRM = "payout/confirm";
export const API_TOPUP_LIST = BaseURL + "topup/payin-list";
export const API_RATE_PAYIN = "rate-create";
export const API_RATE_LIST = BaseURL + "rate-list";
export const API_INCOME_PAYOUT_FEE = "income/payout-fee";
export const API_INCOME_EXCHANGE_FEE = "income/exchange-fee";
export const API_INCOME_TRANSFER_FEE = "income/transfer-fee";
export const API_PAYMENT_BENEFICIARY = "payment/beneficiary";
export const API_PEYMENT_CONFIRM = "payment/confirm";
export const API_CREATE_EXCHANGE = "create-exchange";
export const API_CONFIRM_EXCHANGE = "confirm-exchange";
export const API_ADMIN_USERS_LIST = BaseURL + "users";
export const API_ADMIN_USERS_PARENT_LIST = BaseURL + "users/list";
export const API_ADMIN_USERS_BALANCE_LIST = BaseURL + "user/balance";
export const API_ADMIN_USER = "user";
export const API_ADMIN_ACCOUNTS = "accounts";
export const API_ADMIN_ADD_ACCOUNT = "account/add";
export const API_ADMIN_DELETE_ACCOUNT = "account/delete";
export const API_ADMIN_HOLD_EDIT_ACCOUNT = "account/hold/edit";
export const API_ADMIN_DELETE_FEE = "payout-fee/delete";
export const API_ADMIN_CHANGE_STATUS = "change-status";
export const API_ADMIN_RESET_PASSWORD = "user/reset-password";
export const API_ADMIN_DELETE_USER = "user/delete";
export const API_ADMIN_RESTORE_USER = "user/restore";
export const API_ADMIN_PARENT_USER = "parent-users";
export const API_ADMIN_PARENT_USER_ZERO = "parent-users-with-zero";
export const API_ADMIN_SUB_USERS = "sub-users";
export const API_ADMIN_SUB_USERS_ZERO = "sub-users-with-zero";
export const API_CREATE_INTERNAL_TRANSFER = "create-transfer";
export const API_CONFIRM_INTERNAL_TRANSFER = "confirm-transfer";
export const API_ADD_INDIVIDUAL_USER = "user/add-individual";
export const API_ADD_BUSINESS_USER = "user/add-business";
export const API_CREATE_PLAN = "/create-sub";
export const API_DELETE_PLAN = "/delete-sub";
export const API_PLAN_LIST = BaseURL + "list-sub";
export const API_GET_IBAN_CHECK = "get-iban-check";
export const API_ANNOUNCEMENT_CREATE = "announcement-create";
export const API_ANNOUNCEMENT_TOGGLE = "announcement-toggle";
export const API_ANNOUNCEMENT_DELETE = "announcement-delete";
export const API_ANNOUNCEMENT_LIST = BaseURL + "announcement-list";
export const API_CREATE_ROLE = "role/create";
export const API_STORE_ROLE = "role/store";
export const API_DELETE_ROLE = BaseURL + "role/delete";
export const API_EDIT_ROLE = "role/edit";
export const API_ROLE_LIST = BaseURL + "role/list";
export const API_CREATE_ADMIN = "create";
export const API_AGENT_LIST = "agent/list";
export const API_CREATE_AGENT = "agent/create";
export const API_EDIT_ADMIN = "edit";
export const API_UPDATE_ADMIN = "update";
export const API_DELETE_ADMIN = "delete";
export const API_ADMIN_LIST = BaseURL + "list";
export const API_TOP_UP_LIST = BaseURL + "topup/list";
