import apiService from "services";
import {
  API_ALL_USERS,
  API_PAYMENT_BENEFICIARY,
  API_PAYOUT_CONFIRM,
  API_PAYOUT_PENDING_SHOW,
  API_TOPUP_PAYIN,
  API_INCOME_PAYOUT_FEE,
  API_INCOME_EXCHANGE_FEE,
  API_INCOME_TRANSFER_FEE,
  API_PEYMENT_CONFIRM,
  API_CREATE_EXCHANGE,
  API_CONFIRM_EXCHANGE,
  API_ADMIN_USER,
  API_ADMIN_ACCOUNTS,
  API_ADMIN_ADD_ACCOUNT,
  API_ADMIN_HOLD_EDIT_ACCOUNT,
  API_ADMIN_CHANGE_FEE,
  API_ADMIN_CHANGE_STATUS,
  API_ADMIN_DELETE_ACCOUNT,
  API_ADMIN_RESET_PASSWORD,
  API_ADMIN_PARENT_USER,
  API_ADMIN_SUB_USERS,
  API_CREATE_INTERNAL_TRANSFER,
  API_CONFIRM_INTERNAL_TRANSFER,
  API_ADD_INDIVIDUAL_USER,
  API_ADD_BUSINESS_USER,
  API_ADMIN_DELETE_USER,
  API_ADMIN_RESTORE_USER,
  API_ADMIN_DELETE_FEE,
  API_ADMIN_PARENT_USER_ZERO,
  API_ADMIN_SUB_USERS_ZERO,
  API_USER_CURRENCIES,
  API_CREATE_PLAN,
  API_ALL_EXCHANGES_LIST,
  API_ALL_TRANSFERS_LIST,
  API_GET_IBAN_CHECK,
  API_DELETE_PLAN,
  API_ANNOUNCEMENT_CREATE,
  API_ANNOUNCEMENT_DELETE,
  API_ANNOUNCEMENT_TOGGLE,
  API_CREATE_ROLE,
  API_STORE_ROLE,
  API_DELETE_ROLE,
  API_ROLE_LIST,
  API_CREATE_ADMIN,
  API_EDIT_ADMIN,
  API_UPDATE_ADMIN,
  API_DELETE_ADMIN,
  API_RATE_PAYIN,
  API_AGENT_LIST,
  API_CREATE_AGENT,
} from "constants/constants";

const apiAllUsers = () => apiService.post(API_ALL_USERS);
const apiUserCurrencies = (params) =>
  apiService.post(API_USER_CURRENCIES, params);
const apiTopupPayin = (params) => apiService.post(API_TOPUP_PAYIN, params);
const apiPayoutPendingShow = (params) =>
  apiService.post(API_PAYOUT_PENDING_SHOW, params);
const apiPayoutConfirm = (params) =>
  apiService.post(API_PAYOUT_CONFIRM, params);
const apiRatePayin = (params) => apiService.post(API_RATE_PAYIN, params);
const apiPaymetBeneficiary = (params) =>
  apiService.post(API_PAYMENT_BENEFICIARY, params);
const apiIncomeTransferFee = (params) =>
  apiService.post(API_INCOME_TRANSFER_FEE, params);
const apiIncomePeyoutFee = (params) =>
  apiService.post(API_INCOME_PAYOUT_FEE, params);
const apiIncomeExchangeFee = (params) =>
  apiService.post(API_INCOME_EXCHANGE_FEE, params);
const apiPaymentConfirm = (params) =>
  apiService.post(API_PEYMENT_CONFIRM, params);
const apiCreateExchange = (params) =>
  apiService.post(API_CREATE_EXCHANGE, params);
const apiConfirmExchange = (params) =>
  apiService.post(API_CONFIRM_EXCHANGE, params);
const apiAdminUser = (params) => apiService.post(API_ADMIN_USER, params);
const apiAdminAccounts = (params) =>
  apiService.post(API_ADMIN_ACCOUNTS, params);
const apiAdminAddAccount = (params) =>
  apiService.post(API_ADMIN_ADD_ACCOUNT, params);
const apiAdminDeleteAccount = (params) =>
  apiService.post(API_ADMIN_DELETE_ACCOUNT, params);
const apiAdminHoldEdit = (params) =>
  apiService.post(API_ADMIN_HOLD_EDIT_ACCOUNT, params);
const apiAdminDeleteFee = (params) =>
  apiService.post(API_ADMIN_DELETE_FEE, params);
const apiAdminChangeStatus = (params) =>
  apiService.post(API_ADMIN_CHANGE_STATUS, params);
const apiAdminResetPassword = (params) =>
  apiService.post(API_ADMIN_RESET_PASSWORD, params);
const apiAdminDeleteUser = (params) =>
  apiService.post(API_ADMIN_DELETE_USER, params);
const apiAdminRestoreUser = (params) =>
  apiService.post(API_ADMIN_RESTORE_USER, params);
const apiAdminParentUser = () => apiService.post(API_ADMIN_PARENT_USER);
const apiAdminParentUserZero = () =>
  apiService.post(API_ADMIN_PARENT_USER_ZERO);
const apiAdminSubUser = (params) =>
  apiService.post(API_ADMIN_SUB_USERS, params);
const apiAdminSubUserZero = (params) =>
  apiService.post(API_ADMIN_SUB_USERS_ZERO, params);
const apiAdminCreateTransfer = (params) =>
  apiService.post(API_CREATE_INTERNAL_TRANSFER, params);
const apiAdminConfirmTransfer = (params) =>
  apiService.post(API_CONFIRM_INTERNAL_TRANSFER, params);
const AddIndividualUser = (params) =>
  apiService.post(API_ADD_INDIVIDUAL_USER, params);
const AddBusinessUser = (params) =>
  apiService.post(API_ADD_BUSINESS_USER, params);
const apiCreatePlan = (params) => apiService.post(API_CREATE_PLAN, params);
const apiDeletePlan = (params) => apiService.post(API_DELETE_PLAN, params);
const apiAllExchangesList = (params) =>
  apiService.post(API_ALL_EXCHANGES_LIST, params);
const apiAllTransfersList = (params) =>
  apiService.post(API_ALL_TRANSFERS_LIST, params);
const apiGetIbanCheck = (params) => apiService.post(API_GET_IBAN_CHECK, params);
const apiAnnouncementCreate = (params) =>
  apiService.post(API_ANNOUNCEMENT_CREATE, params);
const apiAnnouncementChangeStatus = (params) =>
  apiService.post(API_ANNOUNCEMENT_TOGGLE, params);
const apiAnnouncementDelete = (params) =>
  apiService.post(API_ANNOUNCEMENT_DELETE, params);
const apiCreateRole = () => apiService.post(API_CREATE_ROLE);
const apiStoreRole = (params) => apiService.post(API_STORE_ROLE, params);
const apiDeleteRole = (params) => apiService.post(API_DELETE_ROLE, params);
const apiRoleList = () => apiService.post(API_ROLE_LIST);
const apiCreateAdmin = (params) => apiService.post(API_CREATE_ADMIN, params);
const apiAgentList = () => apiService.get(API_AGENT_LIST);
const apiCreateAgent = (params) => apiService.post(API_CREATE_AGENT, params);
const apiEditAdmin = (params) => apiService.post(API_EDIT_ADMIN, params);
const apiUpdateAdmin = (params) => apiService.post(API_UPDATE_ADMIN, params);
const apiDeleteAdmin = (params) => apiService.post(API_DELETE_ADMIN, params);

const APIs = {
  apiAllUsers,
  apiUserCurrencies,
  apiTopupPayin,
  apiPayoutPendingShow,
  apiPayoutConfirm,
  apiRatePayin,
  apiPaymetBeneficiary,
  apiIncomeTransferFee,
  apiIncomePeyoutFee,
  apiIncomeExchangeFee,
  apiPaymentConfirm,
  apiCreateExchange,
  apiConfirmExchange,
  apiAdminUser,
  apiAdminAccounts,
  apiAdminAddAccount,
  apiAdminDeleteAccount,
  apiAdminHoldEdit,
  apiAdminDeleteFee,
  apiAdminChangeStatus,
  apiAdminResetPassword,
  apiAdminDeleteUser,
  apiAdminRestoreUser,
  apiAdminParentUser,
  apiAdminParentUserZero,
  apiAdminSubUser,
  apiAdminSubUserZero,
  apiAdminCreateTransfer,
  apiAdminConfirmTransfer,
  AddIndividualUser,
  AddBusinessUser,
  apiCreatePlan,
  apiDeletePlan,
  apiAllExchangesList,
  apiAllTransfersList,
  apiGetIbanCheck,
  apiAnnouncementCreate,
  apiAnnouncementChangeStatus,
  apiAnnouncementDelete,
  apiCreateRole,
  apiStoreRole,
  apiDeleteRole,
  apiRoleList,
  apiCreateAdmin,
  apiAgentList,
  apiCreateAgent,
  apiEditAdmin,
  apiUpdateAdmin,
  apiDeleteAdmin,
};

export default APIs;
