import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useDispatch } from "react-redux";
import { getUsersRegistrationAction } from "redux/actions/usersActions";

const SalesStatistic = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersRegistrationAction(setData))
  }, []);

  const options = {
    chart: { type: "column" },
    plotOptions: {
      area: {
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },

    title: {
      text: "User Registration",
    },
    subtitle: {
      text: "Last 30 Days",
    },
    xAxis: {
      categories: data.registrationCount && data.registrationCount.map((item) => item.day),
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Count",
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
      },
      column: {
        borderRadius: 5,
      },
    },
    series: [
      {
        type: "column",
        name: "Users",
        marker: {
          symbol: "",
        },
        data: data.registrationCount && data.registrationCount.map((item) => item.count),
      },
    ],
  };

  return (
    <div className="jr-card">
      <div className="row mb-3">
        <div className="col-6 col-sm-4 col-md-3 col-lg-2">
          <span className="d-flex align-items-center mb-2">
            <i className="zmdi zmdi-calendar-check text-muted chart-f20" />
            <span className="ml-3 text-dark">{data.allUserCount}</span>
          </span>
          <p className="text-muted">Total Users</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-12 mb-5 mb-lg-1">
          <ResponsiveContainer width="100%">
            <HighchartsReact highcharts={Highcharts} options={options} />
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SalesStatistic);
