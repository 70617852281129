import {
  GET_AGENT_TRANSACTIONS_FAILURE,
  GET_AGENT_TRANSACTIONS_STARTED,
  GET_AGENT_TRANSACTIONS_SUCCESS,
  GET_AGENTS_LIST_FAILURE,
  GET_AGENTS_LIST_STARTED,
  GET_AGENTS_LIST_SUCCESS,
  SET_AGENT,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: null,
  // agentsList: { records: [] },
  agentsList: [],
  transactions: { records: [], count: 0 },
  selectedAgent: "all",
  error: "",
};
export const AgentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENTS_LIST_STARTED:
    case GET_AGENT_TRANSACTIONS_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_AGENTS_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_AGENTS_LIST_SUCCESS,
        agentsList: action.payload.agentsList,
        error: "",
      };
    case GET_AGENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        rType: GET_AGENT_TRANSACTIONS_SUCCESS,
        transactions: action.payload.transactions,
        error: "",
      };
    case GET_AGENTS_LIST_FAILURE:
    case GET_AGENT_TRANSACTIONS_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    case SET_AGENT:
      return {
        ...state,
        selectedAgent: action.payload,
      };
    default:
      return state;
  }
};
