import { createTheme } from "@mui/material";
import indigo from "@mui/material/colors/indigo";
import pink from "@mui/material/colors/pink";
import {
  blackColor,
  errorColor,
  primaryColor,
  successColor,
  warningColor,
  whiteColor,
} from "assets/colors";

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      // light: indigo[300],
      // main: indigo[500],
      // dark: indigo[700],
      contrastText: whiteColor,
    },
    // secondary: {
    //   light: pink[300],
    //   main: pink["A200"],
    //   dark: pink[700],
    //   contrastText: "#fff",
    // },
    success: {
      main: successColor,
    },
    error: {
      main: errorColor,
    },
    warning: {
      main: warningColor,
    },
    black: {
      main: blackColor,
    },
    white: {
      main: whiteColor,
    },
  },
  status: {
    danger: "orange",
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: "capitalize",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
        },
      },
    },
  },
});
