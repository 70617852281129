import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { downloadFileAPI } from "services/API/fileManager";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useParams } from "react-router-dom";
import { changeStatusKycFieldRequestAction } from "redux/actions/customerActions";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import FileSaver from "file-saver";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import AppDropDown from "components/AppDropDown";
import AppTextInput from "components/AppTextInput";
import { LoadingButton } from "@mui/lab";
import { errorColor } from "assets/colors";

let PDFJS = window.pdfjsLib;

const CardMedia = ({ fileAddress: item }) => {
  const dispatch = useDispatch();
  const [images, setImages] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [isDeclined, setIsDeclined] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editable, setEditable] = useState(true);
  const [rotation, setRotation] = useState(0);
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");

  const statusItems = [{ label: "Other", value: "other" }];

  const rotateClockwise = () => setRotation(rotation + 90);
  const rotateCounterClockwise = () => setRotation(rotation - 90);

  const { id: customerId } = useParams();

  useEffect(() => {
    item && showPdf();
  }, [item]);

  const download = () => {
    let url = pdf || images;
    let documentUrl = item?.value;
    let fileName = documentUrl.substring(documentUrl.lastIndexOf("/") + 1);
    FileSaver.saveAs(url, fileName);
  };

  async function showPdf() {
    try {
      let response = await downloadFileAPI({ fileAddress: item?.value });
      let base64Data = `data:${response.data.type};base64,${response.data.value}`;
      if (response.status === "success") {
        if (response.data.type === "application/pdf") {
          let _PDF_DOC = await PDFJS.getDocument(base64Data);
          renderPage(_PDF_DOC);
          setPdf(base64Data);
        } else {
          setImages(base64Data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function renderPage(pdf) {
    const canvas = document.createElement("canvas");

    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };
    console.log("page lenght", pdf.numPages);

    await page.render(render_context).promise;
    let img = canvas.toDataURL("image/png");
    setImages(img);
  }

  const renderConfirmDialog = (
    <Dialog open={isDeclined} onClose={() => setIsDeclined(false)}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppDropDown
              label="Reason"
              items={statusItems}
              onChange={(e) => setReason(e.target.value)}
              value={reason}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {reason === "other" && (
              <AppTextInput
                label="Description"
                onChange={(e) => setDescription(e.target.value)}
                multiline
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setIsDeclined(false);
            setEdit(false);
          }}
          fullWidth
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() =>
            dispatch(
              changeStatusKycFieldRequestAction({
                id: item.id,
                description: reason !== "other" ? reason : description,
                status: "Declined",
                customerId,
                setIsDeclined,
                setEdit,
                setLoading: setDeclineLoading,
              })
            )
          }
          loading={declineLoading}
          fullWidth
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <Card elevation={0}>
      <CardHeader
        title={item.label}
        titleTypographyProps={{ sx: { textAlign: "center", fontSize: 16 } }}
      />
      <TransformWrapper limitToBounds={false} minScale={0.5}>
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <>
                <TransformComponent>
                  <img
                    src={images}
                    style={{
                      transform: `rotate(${rotation}deg)`,
                      width: "400px",
                    }}
                  />
                </TransformComponent>
              </>
            </CardContent>
            {item.errorMessage && (
              <CardContent>
                <Typography sx={{ textAlign: "center", color: errorColor }}>
                  {item.errorMessage}
                </Typography>
              </CardContent>
            )}
            <CardActions sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              {item?.status !== "Initial" && !edit && editable ? (
                <IconButton color="primary" onClick={() => setEdit(true)}>
                  <EditIcon />
                </IconButton>
              ) : editable ? (
                <>
                  <IconButton onClick={() => setIsDeclined(true)}>
                    {declineLoading ? (
                      <CircularProgress size={21} color="error" />
                    ) : (
                      <CancelOutlinedIcon color="error" />
                    )}
                  </IconButton>
                  <IconButton
                    color="success"
                    onClick={() =>
                      dispatch(
                        changeStatusKycFieldRequestAction({
                          id: item?.id,
                          status: "Approved",
                          customerId,
                          setIsDeclined,
                          setEdit,
                          setLoading: setApproveLoading,
                        })
                      )
                    }
                  >
                    {approveLoading ? (
                      <CircularProgress size={21} color="success" />
                    ) : (
                      <CheckCircleOutlineIcon />
                    )}
                  </IconButton>
                </>
              ) : (
                <></>
              )}
              {!edit &&
                (item?.status === "Approved" ? (
                  <CheckCircleIcon color="success" />
                ) : item?.status === "Declined" ? (
                  <CancelIcon color="error" />
                ) : item?.status === "Pending" ? (
                  <ErrorIcon color="warning" />
                ) : null)}
              <IconButton color="primary" onClick={download}>
                <FileDownloadOutlinedIcon />
              </IconButton>
              <IconButton onClick={() => zoomIn()}>
                <ZoomInIcon />
              </IconButton>
              <IconButton onClick={() => zoomOut()}>
                <ZoomOutIcon />
              </IconButton>
              <IconButton onClick={() => resetTransform()}>
                <ZoomOutMapIcon />
              </IconButton>
              <IconButton onClick={rotateClockwise}>
                <RotateRightIcon />
              </IconButton>
              <IconButton onClick={rotateCounterClockwise}>
                <RotateLeftIcon />
              </IconButton>
            </CardActions>
          </>
        )}
      </TransformWrapper>
      {renderConfirmDialog}
    </Card>
  );
};

export default CardMedia;
