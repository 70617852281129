import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, IconButton, Grid, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomTable from "components/CustomTable";
import withDateRangePicker from "HOC/withDateRangePicker";
import DropDown from "components/DropDown";
import {
  getAdminsListRequestAction,
  setAdmin,
} from "redux/actions/adminActions";
import { subjects } from "constant";
import PaginationTable from "components/PaginationTable";
import { GET_ADMIN_ACTIVITY_LOG_LIST } from "config/apiRoutes";
// --------------------------------------------------------------------------------
const AdminActivityLogs = ({ data, renderTopToolbar, from, to }) => {
  const dispatch = useDispatch();

  const { adminActivityLogs } = useSelector((state) => ({
    adminActivityLogs: state.log.adminActivityLogs,
  }));

  const { admins } = useSelector((state) => ({
    admins: state.admins.adminList,
  }));

  const paginationTableRef = useRef();

  const admin = useSelector((state) => state.admins.selectedAdmin) ?? 0;

  const flattenedSubjects = subjects.flat();

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const [subject, setSubject] = useState(0);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(getAdminsListRequestAction());
  }, []);

  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, [subject, admin]);

  function formatProperties(properties) {
    let formattedString = "";

    if (properties.attributes) {
      const formattedAttributes = Object.entries(properties.attributes)
        .map(([key, value]) => `${key} : ${value}`)
        .join(",\n");
      formattedString += `Attributes:\n${formattedAttributes}`;
    }

    if (properties.old) {
      if (formattedString) formattedString += "\n\n";
      const formattedOld = Object.entries(properties.old)
        .map(([key, value]) => `${key} : ${value}`)
        .join(",\n");
      formattedString += `Old:\n${formattedOld}`;
    }

    if (properties.full_name) {
      if (formattedString) formattedString += "\n\n";
      formattedString += `Full_name:  ${properties.full_name}`;
    }

    return formattedString || "No properties available";
  }

  const handleCopy = (properties) => {
    console.log(properties);
    const formattedProperties = formatProperties(properties);
    navigator.clipboard
      .writeText(formattedProperties)
      .then(() => {
        console.log("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "subject",
        header: "Subject",
      },
      {
        accessorKey: "event",
        header: "Event",
      },
      {
        accessorKey: "causer",
        header: "Causer",
      },
      {
        accessorKey: "properties",
        header: "Properties",
        Cell: ({ cell, row }) => (
          <IconButton
            onClick={() =>
              //navigator.clipboard.writeText(JSON.stringify(row.original.properties, null, 2))
              handleCopy(row.original.properties)
            }
          >
            <ContentCopyIcon color="primary" />
          </IconButton>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  return (
    <PaginationTable
      ref={paginationTableRef}
      apiEndpoint={GET_ADMIN_ACTIVITY_LOG_LIST}
      title="Admin Activity Logs"
      columns={columns}
      data={adminActivityLogs?.records || []}
      renderTopToolbar={
        <>
          <Grid item>
            <Box width={238}>
              <DropDown
                label="Subject"
                schema={{ label: "name", value: "id" }}
                items={flattenedSubjects}
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box width={238}>
              <DropDown
                label="Admin"
                schema={{ label: "name", value: "id" }}
                items={
                  admins
                    ? [{ name: "ALL", id: 0 }, ...admins]
                    : [{ name: "ALL", id: 0 }]
                }
                value={admin}
                onChange={(event) => dispatch(setAdmin(event.target.value))}
              />
            </Box>
          </Grid>
        </>
      }
      initialFilters={{ subject: subject, admin: admin }}
    />
  );
};

export default AdminActivityLogs;
