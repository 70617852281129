import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress } from "@mui/material";
import { token, API_GET_SEARCH } from "constants/constants";
import debounce from "lodash/debounce";
import "./searchbox.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SearchBox({ styleName, history }) {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);

  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [loading, setLoading] = useState(false);

  const authToken = useSelector((state) => {
    return state.auth.token;
  });

  useEffect(() => {
    if (inputValue.length > 3) request();
  }, [inputValue]);

  const request = () => {
    if (!loading) setLoading(true);
    axios
      .get(`${API_GET_SEARCH}?keyword=${String(inputValue)}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setOptions(res.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${option.firstName} ${option.lastName}` +
      option.email +
      option.mobile?.toString() +
      option.iban?.toString() +
      option.website_domain +
      option?.channel_name +
      option?.wallet +
      option?.cardName +
      option?.cardId +
      option?.pepId,
  });

  const handleChange = debounce((newValue) => {
    setInputValue(newValue);
  }, 400);

  return (
    <div
      className={`searchbox search-bar right-side-icon bg-transparent ${styleName}`}
      style={{ width: "320px", maxWidth: "420px" }}
    >
      <Autocomplete
        id="combo-box-demo"
        fullWidth
        options={options}
        getOptionLabel={(option) =>
          option.firstName && option.lastName
            ? `${option.firstName} ${option.lastName}`
            : ""
        }
        loading={loading}
        filterOptions={filterOptions}
        renderOption={(e, option) => {
          return (
            <div
              key={option.id}
              onClick={(v) => {
                e.onClick(v);
                navigate(`/app/profile/${String(option.id)}/Summary`);
              }}
              className={`${e.className} option-container`}
            >
              <div>
                <span>Name: </span>
                {String(option.firstName) + " " + String(option.lastName)}
              </div>
              <div>
                <span>Mobile:</span> {String(option.mobile)}
              </div>
              <div>
                <span>Email:</span> {String(option.email)}
              </div>
            </div>
          );
        }}
        onInputChange={(_, newValue) => {
          handleChange(newValue);
        }}
        onChange={(_, newValue) => {
          setValue(newValue);
          setLoading(false);
        }}
        value={value}
        disablePortal
        clearOnEscape
        autoHighlight
        blurOnSelect
        selectOnFocus
        handleHomeEndKeys
        onBlur={() => {
          setInputValue("");
          setLoading(false);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              // label="Search"
              placeholder="Search"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <div
                    style={{
                      backgroundColor: "white",
                      height: 36,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {loading && <CircularProgress color="primary" size={30} />}

                    {params.InputProps.endAdornment}
                  </div>
                ),
              }}
            />
          );
        }}
      />
    </div>
  );
}

export default SearchBox;
