import axiosMiddleware from "./axiosMiddleware";

export const getUsersLogAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `log/list?limit=${limit}&offset=${offset}}`,
  });
};
export const getPepLogsAPI = ({ limit, offset, from, to }) => {
  return axiosMiddleware({
    method: "get",
    url: `log/pep/list?limit=${limit}&offset=${offset}&from=${from}&to=${to}`,
  });
};

export const getAdminActivityLogsAPI = ({ limit, offset, from, to , subject , admin }) => {
  let url = `log/activity/list?limit=${limit}&offset=${offset}&from=${from}&to=${to}`;
  if (subject) {
    url += `&subject=${subject}`;
  }
  if (admin) {
    url += `&admin=${admin}`;
  }

  return axiosMiddleware({
    method: "get",
    url: url,
  });
};

export const getDashboardActivityLogsAPI = ({ limit, offset, from, to , subject , admin }) => {
  let url = `log/dashboard/activity/list?limit=${limit}&offset=${offset}&from=${from}&to=${to}`;
  if (subject) {
    url += `&subject=${subject}`;
  }
  if (admin) {
    url += `&admin=${admin}`;
  }

  return axiosMiddleware({
    method: "get",
    url: url,
  });
};