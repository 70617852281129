import React, { useEffect, useMemo, useRef, useState } from "react";
import {  IconButton } from "@mui/material";
import {  useSelector } from "react-redux";
import { getVirtualCardsListRequestAction } from "redux/actions/cardActions";
import {
  getPepLogsRequestAction,
  getUsersLogRequestAction,
} from "redux/actions/logActions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import withDateRangePicker from "HOC/withDateRangePicker";
import { GET_PEP_LOG_LIST } from "config/apiRoutes";
import PaginationTable from "components/PaginationTable";
// --------------------------------------------------------------------------------
const PepLogs = () => {

  const { pepLogs } = useSelector((state) => ({
    pepLogs: state.log.pepLogs,
  }));

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const paginationTableRef = useRef();


  useEffect(() => {
    !isInitialLoad
      ? paginationTableRef.current.refetch()
      : setIsInitialLoad(false);
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "url",
        header: "Url",
      },
      {
        accessorKey: "type",
        header: "Type",
      },
      {
        accessorKey: "errorCode",
        header: "Error Code",
      },
      {
        accessorKey: "requestBody",
        header: "Request Body",
        Cell: ({ cell, row }) => (
          <IconButton
            onClick={() =>
              navigator.clipboard.writeText(row.original.requestBody)
            }
          >
            <ContentCopyIcon color="primary" />
          </IconButton>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "responseBody",
        header: "Response Body",
        Cell: ({ cell, row }) => (
          <IconButton
            onClick={() =>
              navigator.clipboard.writeText(row.original.responseBody)
            }
          >
            <ContentCopyIcon color="primary" />
          </IconButton>
        ),
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
    ],
    []
  );

  return (
    <PaginationTable
      ref={paginationTableRef}
      apiEndpoint={GET_PEP_LOG_LIST}
      title="Pep Logs"
      columns={columns}
      data={pepLogs.records}
      onPaginationChange={setPagination}
      state={{ pagination, loading: false }}
      rowCount={pepLogs.count}
      
    />
  );
};

export default PepLogs;
