import React from "react";
import TextField from "@mui/material/TextField";
import MuiAutocomplete from "@mui/material/Autocomplete";
import { CircularProgress, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
// --------------------------------------------------------------------------------
const Autocomplete = ({
  name,
  label,
  value,
  options,
  getOptionLabel,
  renderOption,
  onChange,
  helperText,
  error,
  startAdornment,
  disabled,
  loading,
  ...otherProps
}) => {
  const defaultHelperText = helperText || "\u200C";
  return (
    <MuiAutocomplete
      sx={{ minWidth: 140 }}
      value={value}
      options={options}
      autoHighlight
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          name={name}
          label={label}
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
            startAdornment: (
              <InputAdornment>
                {loading ? <CircularProgress size={20} /> : startAdornment}
              </InputAdornment>
            ),
          }}
          error={error}
          helperText={defaultHelperText}
          InputLabelProps={{ shrink: "true" }}
        />
      )}
      onChange={onChange}
      disableClearable
      disabled={disabled}
      {...otherProps}
    />
  );
};

export default Autocomplete;

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .Mui-error.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.error.main,
  },
}));
