import React from "react";
import { Redirect, Route, Routes } from "react-router-dom";
import asyncComponent from "../../../../util/asyncComponent";

const Tickets = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Routes>
        {/* <Redirect exact from={`${match.url}/`} to={`${match.url}/Tickets`} /> */}
        <Route
          path={`${match.url}/all-tickets`}
          component={asyncComponent(() => import("./routes/AllTickets"))}
        />
        <Route
          path={`${match.url}/open-tickets`}
          component={asyncComponent(() => import("./routes/OpenTickets"))}
        />
        {/* <Route
          path={`${match.url}/ticket-page`}
          component={asyncComponent(() => import("./routes/TicketPage"))}
        /> */}
        <Route
          path={`${match.url}/new-ticket`}
          component={asyncComponent(() => import("./routes/NewTicket"))}
        />
      </Routes>
    </div>
  );
};

export default Tickets;
