import React, { useState, useEffect } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import axios from "axios";
import { API_CHANGE_STATUS, token } from "constants/constants";
import CircularProgress from "@mui/material/CircularProgress";
import useAppSnackbar from "hooks/useAppSnackbar";
import CardHeader from "components/CardHeader";
import AppSwitch from "components/AppSwitch";
import DescriptionDialog from "components/DescriptionDialog";

const UserStatus = ({ basicInfo, refreshUserData }) => {
  const [status, setStatus] = useState({});
  const [file, setFile] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const [reasonDialog, setReasonDialog] = useState({
    isOpen: "",
    reason: "",
    tempStatus: "",
  });

  const { Snackbar } = useAppSnackbar();

  useEffect(() => {
    setStatus({
      top_up_status: basicInfo.top_up_status === "Active",
      exchange_status: basicInfo.exchange_status === "Active",
      refund_status: basicInfo.refund_status === "Active",
      transfer_status: basicInfo.transfer_status === "Active",
      payout_status: basicInfo.payout_status === "Active",
      payment_link_status: basicInfo.payment_link_status === "Active",
      gateway_status: basicInfo.gateway_status === "Active",
      personal_gateway_status: basicInfo.personal_gateway_status === "Active",
      irr_payment_link_status: basicInfo.irr_payment_link_status === "Active",
      irr_personal_gateway_status:
        basicInfo.irr_personal_gateway_status === "Active",
      all: basicInfo.status,
    });

    if (basicInfo.status) {
      setLoading(false);
    }
  }, [basicInfo]);

  const handleChange = (e, field) => {
    const event = e.target;

    setReasonDialog({
      isOpen: true,
      tempStatus: field === "all" ? event?.value : event?.checked,
      field,
    });
  };

  function onChangeFile(e) {
    setFile(e.target.files[0]);
  }

  const handleSubmit = async () => {
    const { reason, tempStatus, field } = reasonDialog;
    if (reason?.length > 3) {
      setBtnLoading(true);
      let value = tempStatus;
      if (field !== "all") {
        if (tempStatus === true) value = "Active";
        else {
          value = "Inactive";
        }
      }

      const data = new FormData();
      data.append("user_id", basicInfo.id);
      data.append("status_field", field === "all" ? "status" : field);
      data.append("reason", reason);
      data.append("value", value);
      data.append("file", file);

      const res = await axios.post(API_CHANGE_STATUS, data, token);
      setStatus({ ...status, [field]: tempStatus });
      setReasonDialog({ isOpen: false });
      setBtnLoading(false);
      if (res.status === 200) Snackbar({ message: "Success" });
      else {
        Snackbar({ message: "Failed", variant: "Error" });
      }
      refreshUserData();
    } else {
      setError(true);
    }
  };

  const handleCloseDialog = () => {
    setReasonDialog({ isOpen: false });
    if (error) setError(false);
  };
  const handleReason = (e) => {
    setReasonDialog({ ...reasonDialog, reason: e.target.value });
    if (error) setError(false);
  };
  const RenderReasonDialog = () => (
    <div className="jr-entry-sec">
      <div>
        <DescriptionDialog
          isOpen={reasonDialog.isOpen}
          onClose={handleCloseDialog}
          onSubmit={handleSubmit}
          dialogTitle={"Resolution"}
          dialogContent={`Please write your reason for changing user status to ${reasonDialog.tempStatus}`}
          description={reasonDialog.reason}
          onDescriptionChange={handleReason}
          error={error}
          attachment={file}
          onChangeAttachment={onChangeFile}
          loading={btnLoading}
        />
      </div>
    </div>
  );

  return (
    <div className="jr-card jr-full-card">
      <CardHeader title="status" />
      <div className="p-3">
        <div className="jr-tabs-content jr-task-list">
          {loading ? (
            <div style={{ minHeight: "250px", textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <RadioGroup
                  className="d-flex justify-content-around mb-3"
                  onChange={(e) => handleChange(e, "all")}
                  value={status.all}
                >
                  <div className="p-2 col-xl-4 text-primary text-center">
                    <FormControlLabel
                      value="Active"
                      control={<Radio color="primary" />}
                      label="Active"
                    />
                  </div>
                  <div className="p-2 col-xl-4 text-warning text-center">
                    <FormControlLabel
                      value="Fraud Suspect"
                      control={<Radio color="default" />}
                      label="Fraud Suspect"
                    />
                  </div>
                  <div className="p-2 col-xl-4 text-danger text-center">
                    <FormControlLabel
                      value="Blocked"
                      control={<Radio color="secondary" />}
                      label="Blocked"
                    />
                  </div>
                </RadioGroup>
              </div>
              <AppSwitch
                // label={<IntlMessages id="status.topUp" />}
                checked={status.top_up_status}
                onChange={(e) => handleChange(e, "top_up_status")}
              />
              <AppSwitch
                // label={<IntlMessages id="status.exchange" />}
                checked={status.exchange_status}
                onChange={(e) => handleChange(e, "exchange_status")}
              />
              <AppSwitch
                // label={<IntlMessages id="status.refund" />}
                checked={status.refund_status}
                onChange={(e) => handleChange(e, "refund_status")}
              />
              <AppSwitch
                // label={<IntlMessages id="status.payout" />}
                checked={status.payout_status}
                onChange={(e) => handleChange(e, "payout_status")}
              />
              <AppSwitch
                // label={<IntlMessages id="status.transfer" />}
                checked={status.transfer_status}
                onChange={(e) => handleChange(e, "transfer_status")}
              />
              <AppSwitch
                // label={<IntlMessages id="status.paymentLink" />}
                checked={status.payment_link_status}
                onChange={(e) => handleChange(e, "payment_link_status")}
              />
              <AppSwitch
                // label={<IntlMessages id="status.gateway" />}
                checked={status.gateway_status}
                onChange={(e) => handleChange(e, "gateway_status")}
              />
              <AppSwitch
                // label={<IntlMessages id="status.personalGateway" />}
                checked={status.personal_gateway_status}
                onChange={(e) => handleChange(e, "personal_gateway_status")}
              />
              <AppSwitch
                label="IRR Yekpay Link"
                checked={status.irr_payment_link_status}
                onChange={(e) => handleChange(e, "irr_payment_link_status")}
              />
              <AppSwitch
                label="IRR Personal Payment Gateway"
                checked={status.irr_personal_gateway_status}
                onChange={(e) => handleChange(e, "irr_personal_gateway_status")}
              />
            </div>
          )}
        </div>
      </div>
      {RenderReasonDialog()}
    </div>
  );
};

export default UserStatus;
