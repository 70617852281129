import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const getAllUsersAPI = ({ limit, offset, from, to, agent }) => {
  let agentParam = agent ? `&agentId=${agent}` : "";
  return axiosMiddleware({
    method: "get",
    url: `users/list?limit=${limit}&offset=${offset}&from=${from}&to=${to}${agentParam}`,
  });
};
export const getPendingUsersAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `users/list/pending?limit=${limit}&offset=${offset}`,
  });
};
export const getApprovedUsersAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `users/list/approved?limit=${limit}&offset=${offset}`,
  });
};
export const getDeclinedUsersAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `/users/list/declined?limit=${limit}&offset=${offset}`,
  });
};
export const getKnowYourCustomerAPI = ({ customerId }) => {
  let postData = {
    userId: customerId,
  };
  return axiosMiddleware({
    method: "post",
    url: "kyc/list",
    data: postData,
  });
};
export const changeStatusKycFieldAPI = ({ id, status, description }) => {
  let postData = {
    id,
    status,
    error_message: description,
  };
  return axiosMiddleware({
    method: "post",
    url: "kyc/changeStatusField",
    data: postData,
  });
};
export const confirmKycAPI = ({ userId }) => {
  let postData = {
    userId,
  };
  return axiosMiddleware({
    method: "post",
    url: "kyc/confirm",
    data: postData,
  });
};
export const makeStarterUserAPI = ({ userId }) => {
  let postData = {
    userId,
  };
  return axiosMiddleware({
    method: "post",
    url: "kyc/starter",
    data: postData,
  });
};
export const makePremiumUserAPI = ({ userId }) => {
  let postData = {
    userId,
  };
  return axiosMiddleware({
    method: "post",
    url: "kyc/premium",
    data: postData,
  });
};
export const getStarterUsersAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `users/list/starter?limit=${limit}&offset=${offset}`,
  });
};
export const getPremiumUsersAPI = ({ limit, offset }) => {
  return axiosMiddleware({
    method: "get",
    url: `/users/list/premium?limit=${limit}&offset=${offset}`,
  });
};
