import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { API_GET_TICKET, boot, token } from "constants/constants";
import {
  createDateLookup,
  lookup,
  lookup2,
  lookup3,
  lookup4,
  updateDateLookup,
} from "constants/usefulFunctions";
import AppButton from "components/AppButton";

function Tickets(props) {
  const { user_id } = props;
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const userEmail = props.history.location?.state?.email;

  const options = {
    columnsButton: true,
    actionsColumnIndex: state.columns && state.columns.length,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: true,
  };

  function getTicket(ticket) {
    return (
      <a target="_blank" href={"/app/tickets/ticket-page/" + ticket.id}>
        {ticket.subject}
      </a>
    );
  }

  useEffect(() => {
    async function fetchData() {
      await axios.post(API_GET_TICKET, { user_id }, token).then((res) => {
        let data = res.data.data;
        setState({
          columns: [
            {
              title: "Subject",
              field: "subject",
              editable: "never",
              render: (rowData) => getTicket(rowData),
            },
            {
              title: "Assignee",
              field: "assignee_id",
              render: (rowData) =>
                boot.admins && rowData.assignee_id
                  ? boot.admins.find((o) => o.id === rowData.assignee_id)
                      .first_name +
                    " " +
                    boot.admins.find((o) => o.id === rowData.assignee_id)
                      .last_name
                  : "-",
              lookup: lookup2(boot.admins),
            },
            {
              title: "Department",
              field: "department_id",
              render: (rowData) =>
                boot.departments && rowData.department_id
                  ? boot.departments.find((o) => o.id === rowData.department_id)
                      .name_en
                  : "-",
              lookup: lookup(boot.departments),
            },
            { title: "Status", field: "status" },
            {
              title: "Elapsed",
              field: "timeago_en",
              render: (rowData) =>
                rowData.status !== "Close" ? rowData.timeago_en : "-",
            },
            createDateLookup(),
            updateDateLookup(),
            { title: "Priority", field: "priority" },
            { title: "Resolutions", hidden: true, field: "resolutions" },
            { title: "Tags", field: "tags", hidden: true },
            { title: "Source", field: "source" },
            { title: "Ticket ID", field: "ticket_id", hidden: true },
            {
              title: "Category",
              field: "parent_category.category_en",
              lookup: lookup4(boot.ticketCategories),
            },
            {
              title: "Sub Category",
              field: "category.category_en",
              lookup: lookup3(boot.ticketCategories),
            },
            { title: "Last Update", field: "last_update", editable: "never" },
            { title: "Resolution", field: "resolution" },
          ],
          data: data,
        });
      });
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className="card">
      <AppButton
        onClick={() =>
          window.open(`/app/tickets/new-ticket?email=${userEmail}`)
        }
        text="Send New Ticket"
        size="large"
        customClassName="m-3"
      />

      <MaterialTable
        isLoading={loading}
        options={options}
        columns={state.columns}
        data={state.data}
        title="Tickets"
      />
    </div>
  );
}

export default Tickets;
