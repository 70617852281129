import APIs from "services/API/index";

export const formItems = [
  {
    name: "name",
    type: "text",
    label: "Name",
    values: null,
    required: true,
  },
  {
    name: "referralCode",
    type: "text",
    label: "Referral Code",
    values: null,
    required: true,
  },
];
