import FormGenerator from "components/FormGenerator/index";
import MessageDialog, {
  INFORMATION_DIALOG,
} from "components/MessageDialog/index";
import React, { useEffect, useState } from "react";
import APIs from "services/API";
import useAPI from "services/hook";
import { formItems } from "./formItems";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card } from "@mui/material";
import { CardContent, CardHeader } from "@mui/material";
import { getAdminInfo } from "redux/actions/adminActions";
import { useDispatch } from "react-redux";

//------------------------------------------------------------------

const AdminRoles = () => {
  
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const {id : adminId} = useParams();
 const [loading, setLoading] = useState(false);
 const [dialogProps, setDialogProps] = useState({
    isOpen: false,
    type: INFORMATION_DIALOG,
  });

  const [admin , setAdmin] = useState();
  console.log(admin)

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    password : '',
    roleID: [],
    agentID:[]
  });

  useEffect(() => {
    if (adminId) {
      dispatch(getAdminInfo({ setAdmin, adminId }));
    }
  }, [adminId, dispatch]);


  useEffect(() => {
    if (admin) {
      setInitialValues({
        firstName: admin.firstName || '',
        lastName: admin.lastName || '',
        mobile: admin.mobile || '',
        email: admin.email || '',
        // password : admin.password || '',
        roleID: admin.roles || [],
        agentID : admin.agents || []
      });
    }
  }, [admin]);

   const apiUpdateAdmin = useAPI(APIs.apiUpdateAdmin);

   const fetchData = async (values) => {

    setLoading(true);

    let expire_at = values?.expire_at
      ? moment(new Date(values?.expire_at)).format("YYYY-MM-DD 23:59:59")
      : null;

    let formData = {
      id : adminId,  
      first_name: values?.firstName,
      last_name: values?.lastName,
      mobile: values?.mobile,
      email: values?.email,
      password: values?.password,
      role_id: values?.roleID,
      agent_id : values?.agentID,
      expire_at
    };


    let result = null;

    console.log(formData)
    result = await apiUpdateAdmin.request(formData);
      
     
    if (result) setDialogProps({ isOpen: true });
    setLoading(false);
  };

  return (
    <Card>
      <CardHeader title="Edit Admin" />
      <CardContent>
        <FormGenerator
          formItems={formItems}
          initialValues={initialValues}
          customClassName="justify-content-center"
          onSubmit={fetchData}
          //onCancel={location?.state?.id ? () => navigate(-1) : null}
          loading={loading}
          
        />
      </CardContent>
      {dialogProps.isOpen && (
        <MessageDialog
          title={"Done successfully"}
          onConfirm={() => {
            //setDialogProps({ isOpen: false });
            navigate("/admin/list");
          }}
        />
      )}
    </Card>
  );
}

export default AdminRoles;