import {
  GET_ADMINS_LIST_FAILURE,
  GET_ADMINS_LIST_STARTED,
  GET_ADMINS_LIST_SUCCESS,
  SET_Admin,
} from "redux/ActionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: null,
  adminList: [],
  selectedAdmin: 0,
  error: "",
};
export const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMINS_LIST_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_ADMINS_LIST_SUCCESS:

    return {
      ...state,
      rType: GET_ADMINS_LIST_SUCCESS,
      adminList: action.payload.adminList.map(admin => ({
        ...admin,
        name: `${admin.first_name} ${admin.last_name}`,
      })),
      error: "",
    };
    case GET_ADMINS_LIST_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    case SET_Admin:
      return {
        ...state,
        selectedAdmin: action.payload,
      };
    default:
      return state;
  }
};
