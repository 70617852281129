import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import AppDropDown from "components/AppDropDown";
import AppNumberInput from "components/AppNumberInput";
import AppTextInput from "components/AppTextInput";
import Autocomplete from "components/Autocomplete";
import { API_GET_SEARCH } from "constants/constants";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { topupCardRequestAction } from "redux/actions/cardActions";
import CloseIcon from "@mui/icons-material/Close";

const TopupCard = () => {
  const dispatch = useDispatch();

  const authToken = useSelector((state) => state.auth.token);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const currencies = [
    { name: "TRY", id: 1 },
    { name: "EUR", id: 2 },
    { name: "USD", id: 3 },
  ];

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${option.firstName} ${option.lastName}` +
      option.email +
      option.mobile?.toString() +
      option.iban?.toString() +
      option.website_domain +
      option?.channel_name,
  });

  useEffect(() => {
    if (inputValue.length > 3) request();
  }, [inputValue]);

  const handleChange = debounce((newValue) => {
    setInputValue(newValue);
  }, 400);

  const request = () => {
    if (!loading) setLoading(true);
    axios
      .get(`${API_GET_SEARCH}?keyword=${String(inputValue)}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setOptions(res.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const handleSubmit = () => {
    dispatch(
      topupCardRequestAction({
        amount,
        userId: value.id,
        currencyId: currency.id,
        onSuccess: () => {
          setShowConfirm(false);
        },
      })
    );
  };

  const handleClose = () => {
    setShowConfirm(false);
  };

  const renderDialogConfirm = (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showConfirm}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Request New Virtual Card
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          First Name:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {value.firstName}
          </Typography>{" "}
        </Typography>
        <Typography gutterBottom>
          Last Name:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {value.lastName}
          </Typography>{" "}
        </Typography>
        <Typography gutterBottom>
          Currency:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {currency.name}
          </Typography>{" "}
        </Typography>
        <Typography gutterBottom>
          Amount:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {amount}
          </Typography>{" "}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ boxShadow: "none" }}
          // onClick={handleClose}
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Card>
        <CardHeader title="Top up Card" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <div
                className={`searchbox right-side-icon bg-transparent d-block`}
                // style={{ width: "320px", maxWidth: "420px" }}
              >
                <Autocomplete
                  id="combo-box-demo"
                  fullWidth
                  options={options}
                  getOptionLabel={(option) =>
                    option.email ? option.email : ""
                  }
                  loading={loading}
                  filterOptions={filterOptions}
                  renderOption={(e, option) => {
                    return (
                      <div
                        key={option.id}
                        onClick={(v) => {
                          // e.onClick(v);
                          // navigate(`/app/profile/${String(option.id)}/Summary`);
                          setValue(option);
                          // dispatch(
                          //   getUserWallet({
                          //     userId: option.id,
                          //     setData: setUserWallets,
                          //   })
                          // );
                        }}
                        {...e}
                        className={`${e.className} option-container`}
                      >
                        <div>
                          <span>Name: </span>
                          {String(option.firstName) +
                            " " +
                            String(option.lastName)}
                        </div>
                        {/* <div>
                    <span>Mobile:</span> {String(option.mobile)}
                  </div> */}
                        <div>
                          <span>Email:</span> {String(option.email)}
                        </div>
                      </div>
                    );
                  }}
                  onInputChange={(_, newValue) => {
                    handleChange(newValue);
                  }}
                  onChange={(_, newValue) => {
                    setValue(newValue);
                    //   dispatch(
                    //     getUserWallet({
                    //       userId: newValue.id,
                    //       setData: setUserWallets,
                    //     })
                    //   );
                    setLoading(false);
                  }}
                  value={value}
                  disablePortal
                  clearOnEscape
                  autoHighlight
                  blurOnSelect
                  selectOnFocus
                  handleHomeEndKeys
                  onBlur={() => {
                    setInputValue("");
                    setLoading(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="User"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <div
                            style={{
                              backgroundColor: "white",
                              height: 36,
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            {loading && (
                              <CircularProgress color="primary" size={30} />
                            )}

                            {params.InputProps.endAdornment}
                          </div>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item lg={3}>
              <AppDropDown
                fullWidth
                label="Currency"
                items={currencies}
                schema={{ label: "name", value: "id" }}
                onChange={(e) => {
                  let value = e.target.value;
                  let selectedCurrency = currencies.find((c) => c.id === value);

                  setCurrency(selectedCurrency);
                }}
              />
            </Grid>
            <Grid item lg={3}>
              <AppNumberInput
                label="Amount"
                fullWidth
                onChange={(e) => setAmount(e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <LoadingButton
            variant="contained"
            sx={{ boxShadow: "none" }}
            onClick={() =>
              //   dispatch(
              //     topupCardRequestAction({ amount, currencyId, userId: value.id })
              //   )
              setShowConfirm(true)
            }
            //   loading={loading}
            //   disabled={!amount || !currencyId}
          >
            Submit
          </LoadingButton>
        </CardActions>
      </Card>
      {renderDialogConfirm}
    </>
  );
};

export default TopupCard;
