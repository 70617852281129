import { capitalizeFirstLetter } from "constants/usefulFunctions";
import MaterialTable from "material-table";
import React, { memo, useCallback } from "react";
import { saveAs } from "file-saver";

const MainTable = ({
  loading,
  columns,
  data = [],
  title = "",
  customOptions,
  isLocalization = true,
  onRowUpdate,
  onRowDelete,
  onRowAdd,
  actions,
  parentChildData,
}) => {
  const options = {
    columnsButton: true,
    search: true,
    exportButton: true,
    grouping: false,
    filtering: true,
    exportAllData: true,
    selection: false,
    editable: false,
    paging: true,
    pageSize: 20,
    pageSizeOptions: [20, 50, 100],
    addRowPosition: "first",
    // exportCsv: (columns, data) => {
    //   exportToCSV(data, title);
    // },

    ...customOptions,
  };

  const localization = {
    header: {
      actions: "",
    },
    body: {
      editRow: { deleteText: "Are you sure you want remove this item ?" },
    },
  };

  const columnsGenerator = useCallback(() => {
    if (data.length) {
      const columns = [];

      Object.keys(data[0])?.map((item) => {
        const splited = item.replaceAll("_", " ").split(" ");
        const capitilizedArr = splited?.map((word) =>
          capitalizeFirstLetter(word)
        );
        const title = capitilizedArr?.join(" ");

        if (item !== "tableData") columns.push({ title, field: item });
      });

      return columns;
    }
  }, [data]);

  return (
    <MaterialTable
      actions={actions}
      isLoading={loading}
      title={title}
      options={options}
      columns={columns ?? columnsGenerator()}
      data={data}
      localization={isLocalization && localization}
      editable={{ onRowUpdate, onRowDelete, onRowAdd }}
      parentChildData={parentChildData}
    />
  );
};

export default memo(MainTable);
