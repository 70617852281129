import React, { useEffect, useState } from "react";
import AppTextInput from "components/AppTextInput";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Button,
} from "@mui/material";
import { downloadFileAPI } from "services/API/fileManager";
import { Modal, ModalContent } from "components/Images/ModalImage";
import { useDispatch } from "react-redux";
import {
  getCardsLimitsRequestAction,
  getCardsStatusRequestAction,
  setCardsLimitsRequestAction,
  updateCardsStatusRequestAction,
} from "redux/actions/cardActions";
import DropDown from "components/DropDown";
import { showMessage } from "components/Modal";
import { getCardDetails } from "redux/actions/usersActions";
import { LoadingButton } from "@mui/lab";

const CardsStatus = ({ cardId, setCard }) => {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (cardId) {
      dispatch(getCardsStatusRequestAction(cardId, setData));
    }
  }, [cardId]);

  // useEffect(() => {
  //   if (data) {
  //     debugger;
  //   }
  // }, [data]);

  return (
    <Card>
      <CardHeader title="Card Status" />
      <CardContent>
        <Grid xs={4}>
          {data && (
            <DropDown
              label="Status"
              items={data}
              value={status}
              onChange={(event) => setStatus(event.target.value)}
            />
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => {
            dispatch(
              updateCardsStatusRequestAction({
                data: { cardId, status },
                setLoading,
                onSuccess: () => {
                  showMessage({
                    status: "success",
                    onSubmit: () => {
                      dispatch(getCardDetails({ setCard, cardId }));
                      dispatch(getCardsStatusRequestAction(cardId, setData));
                    },
                  });
                },
              })
            );
          }}
        >
          Submit
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default CardsStatus;
