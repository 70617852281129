import {
  GET_USERS_STARTED,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_CURRENCIES_STARTED,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
  TOP_UP_STARTED,
  TOP_UP_SUCCESS,
  TOP_UP_FAILURE,
  CREATE_USER_DEFAULT_WALLET_STARTED,
  CREATE_USER_DEFAULT_WALLET_SUCCESS,
  CREATE_USER_DEFAULT_WALLET_FAILURE,
  GET_USER_DEFAULTS_STARTED,
  GET_USER_DEFAULTS_SUCCESS,
  GET_USER_DEFAULTS_FAILURE,
  GET_USER_FEES_SUCCESS,
  CREATE_USER_FEES_WALLET_STARTED,
  CREATE_USER_FEES_WALLET_SUCCESS,
  CREATE_USER_FEES_WALLET_FAILURE,
  EDIT_WALLET_MINIMUM_BALANCE_STARTED,
  EDIT_WALLET_MINIMUM_BALANCE_SUCCESS,
  EDIT_WALLET_MINIMUM_BALANCE_FAILURE,
  GET_USER_DOCUMENTS_STARTED,
  GET_USER_DOCUMENTS_SUCCESS,
  GET_USER_DOCUMENTS_FAILURE,
  DOWNLOAD_FILE_STARTED,
  DOWNLOAD_FILE_SUCCESS,
  DOWNLOAD_FILE_FAILURE,
  UPLOAD_FILE_STARTED,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
} from "../ActionTypes";
import {
  createUserDefaultWalletAPI,
  createUserFeesAPI,
  deleteUserFeesAPI,
  getCurrenciesAPI,
  getPepBalancesAPI,
  getUserDefaultFeeAPI,
  getUserDefaultsAPI,
  getUserDetailsAPI,
  getCardDetailsAPI,
  getUserFeesAPI,
  getUserInfoAPI,
  getUserTransactionsAPI,
  getUserWalletAPI,
  getUsersAPI,
  getUsersBalancesAPI,
  getUsersRegistrationAPI,
  getUserPhysicalCardAPI,
  getUserVirtualCardAPI,
  getCardTransactionsAPI,
  getUsersSettingsAPI,
  setUsersSettingsAPI,
  getUserFeesListAPI,
  getUserLogsAPI,
  setUserFeesAPI,
  editWalletMinimumBalanceAPI,
  getUserDocumentsAPI,
  setAdminFeesAPI,
} from "services/API/users";
import { topupUserAPI, topupAdminAPI } from "services/API/topup";
import { showMessage } from "components/Modal";
import { downloadFileAPI, uploadFileAPI } from "services/API/fileManager";
// --------------------------------------------------------------------------------
export const getUsersRequestAction = ({ setUsers }) => {
  return async (dispatch) => {
    dispatch(getUsersStartedAction());
    try {
      let response = await getUsersAPI();
      if (!response.error) {
        dispatch(getUsersSuccessAction());
        setUsers(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};

export const getUsersStartedAction = () => ({
  type: GET_USERS_STARTED,
});

export const getUsersSuccessAction = (payload) => ({
  type: GET_USERS_SUCCESS,
  payload,
});

export const getUsersFailureAction = (message) => ({
  type: GET_USERS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const getCurrenciesRequestAction = () => {
  return async (dispatch) => {
    dispatch(getCurrenciesStartedAction());
    try {
      let response = await getCurrenciesAPI();
      if (!response.error) {
        dispatch(getCurrenciesSuccessAction(response.data));
      } else {
        dispatch(getCurrenciesFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getCurrenciesFailureAction(error.error_description));
    }
  };
};

export const getCurrenciesStartedAction = () => ({
  type: GET_CURRENCIES_STARTED,
});

export const getCurrenciesSuccessAction = (payload) => ({
  type: GET_CURRENCIES_SUCCESS,
  payload,
});

export const getCurrenciesFailureAction = (message) => ({
  type: GET_CURRENCIES_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const topUpRequestAction = ({
  walletId,
  userId,
  amount,
  description,
  onSuccess,
  file,
  setLoading,
}) => {
  return async (dispatch) => {
    dispatch(topUpStartedAction());
    setLoading(true);
    try {
      let response = await topupUserAPI({
        walletId,
        userId,
        amount,
        description,
        file,
      });
      if (!response.error) {
        setLoading(false);
        dispatch(topUpSuccessAction());
        onSuccess();
      } else {
        setLoading(false);
        dispatch(topUpFailureAction(response.data.error_description));
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
      dispatch(topUpFailureAction(error.error_description));
    }
  };
};

export const topUpAdminRequestAction = ({
  walletId,
  adminId,
  amount,
  description,
  onSuccess,
  file,
  setLoading,
}) => {
  return async (dispatch) => {
    dispatch(topUpStartedAction());
    setLoading(true);
    try {
      let response = await topupAdminAPI({
        walletId,
        adminId,
        amount: parseFloat(amount),
        description,
        file,
      });
      if (!response.error) {
        setLoading(false);
        dispatch(topUpSuccessAction());
        onSuccess();
      } else {
        setLoading(false);
        dispatch(topUpFailureAction(response.data.error_description));
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
      dispatch(topUpFailureAction(error.error_description));
    }
  };
};

export const topUpStartedAction = () => ({
  type: TOP_UP_STARTED,
});

export const topUpSuccessAction = (payload) => ({
  type: TOP_UP_SUCCESS,
  payload,
});

export const topUpFailureAction = (message) => ({
  type: TOP_UP_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const createUserDefaultWalletRequestAction = ({
  currencies,
  agentId,
  onSuccess,
  setLoading,
}) => {
  return async (dispatch) => {
    dispatch(createUserDefaultWalletStartedAction());
    setLoading(true);
    try {
      let response = await createUserDefaultWalletAPI({ currencies, agentId });
      if (!response.error) {
        setLoading(false);
        dispatch(createUserDefaultWalletSuccessAction());
        onSuccess();
      } else {
        setLoading(false);
        dispatch(
          createUserDefaultWalletFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      setLoading(false);
      dispatch(createUserDefaultWalletFailureAction(error.error_description));
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
    }
  };
};

export const createUserDefaultWalletStartedAction = () => ({
  type: CREATE_USER_DEFAULT_WALLET_STARTED,
});

export const createUserDefaultWalletSuccessAction = (payload) => ({
  type: CREATE_USER_DEFAULT_WALLET_SUCCESS,
  payload,
});

export const createUserDefaultWalletFailureAction = (message) => ({
  type: CREATE_USER_DEFAULT_WALLET_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------

export const createUserFeesRequestAction = ({
  postData,
  onSuccess,
  setLoading,
}) => {
  return async (dispatch) => {
    dispatch(createUserFeesStartedAction());
    setLoading(true);
    try {
      let response = await createUserFeesAPI(postData);
      if (!response.error) {
        setLoading(false);
        dispatch(createUserFeesSuccessAction());
        onSuccess();
      } else {
        setLoading(false);
        dispatch(createUserFeesFailureAction(response.data.error_description));
      }
    } catch (error) {
      setLoading(false);
      dispatch(createUserFeesFailureAction(error.error_description));
    }
  };
};

export const createUserFeesStartedAction = () => ({
  type: CREATE_USER_FEES_WALLET_STARTED,
});

export const createUserFeesSuccessAction = (payload) => ({
  type: CREATE_USER_FEES_WALLET_SUCCESS,
  payload,
});

export const createUserFeesFailureAction = (message) => ({
  type: CREATE_USER_FEES_WALLET_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------

export const getUserDefaultsRequestAction = () => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserDefaultsAPI();
      if (!response.error) {
        dispatch(getUserDefaultsSuccessAction({ userDefaults: response.data }));
      } else {
        dispatch(getUserDefaultsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUserDefaultsFailureAction(error.error_description));
    }
  };
};
export const setUserFeesRequestAction = (data, setLoading, setFee) => {
  return async (dispatch) => {
    setLoading(true);
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await setUserFeesAPI(data);
      if (!response.error) {
        setLoading(false);
        setFee(response.data);
        showMessage({
          status: "success",
        });
      } else {
        setLoading(false);
        dispatch(getUserDefaultsFailureAction(response.data.error_description));
      }
    } catch (error) {
      setLoading(false);
      showMessage({
        description: error.error_description.message,
        status: "error",
      });
      dispatch(getUserDefaultsFailureAction(error.error_description));
    }
  };
};

export const getUserFeesRequestAction = (setData) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserFeesAPI();
      if (!response.error) {
        dispatch(getUserFeesSuccessAction({ userFees: response.data }));
        setData(response.data);
      } else {
        dispatch(getUserDefaultsFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUserDefaultsFailureAction(error.error_description));
    }
  };
};

export const getUserDefaultsStartedAction = () => ({
  type: GET_USER_DEFAULTS_STARTED,
});

export const getUserDefaultsSuccessAction = (payload) => ({
  type: GET_USER_DEFAULTS_SUCCESS,
  payload,
});
export const getUserFeesSuccessAction = (payload) => ({
  type: GET_USER_FEES_SUCCESS,
  payload,
});

export const getUserDefaultsFailureAction = (message) => ({
  type: GET_USER_DEFAULTS_FAILURE,
  payload: message,
});

export const getUsersBalancesAction = ({ setBalances }) => {
  return async (dispatch) => {
    dispatch(getUsersStartedAction());
    try {
      let response = await getUsersBalancesAPI();
      if (!response.error) {
        dispatch(getUsersSuccessAction());
        setBalances(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};

export const getPepBalancesAction = ({ setBalances }) => {
  return async (dispatch) => {
    dispatch(getUsersStartedAction());
    try {
      let response = await getPepBalancesAPI();
      if (!response.error) {
        dispatch(getUsersSuccessAction());
        setBalances(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};

export const getUserInfo = ({ setUser, userId }) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserInfoAPI({ userId });
      if (!response.error) {
        dispatch(getUsersSuccessAction());
        setUser(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};

export const getUserDetails = ({ setUser, userId }) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserDetailsAPI({ userId });
      if (!response.error) {
        dispatch(getUsersSuccessAction());
        setUser(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};

export const getCardDetails = ({ setCard, cardId, onSuccess }) => {
  return async (dispatch) => {
    dispatch(getUsersStartedAction());
    try {
      let response = await getCardDetailsAPI({ cardId });
      if (!response.error) {
        setCard(response.data);
        if (onSuccess) onSuccess(response.data);
        dispatch(getUsersSuccessAction());
      } else {
        console.log(response.data.error_description);
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};
export const getUserWallet = ({ setData, userId }) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserWalletAPI({ userId });
      if (!response.error) {
        dispatch(getUsersSuccessAction());
        setData(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};

export const editWalletMinimumBalanceRequestAction = ({
  walletId,
  minBalance,
  setData,
  userId,
}) => {
  return async (dispatch) => {
    dispatch(editWalletMinimumBalanceStartedAction());
    try {
      let response = await editWalletMinimumBalanceAPI({
        walletId,
        minBalance,
      });
      if (!response.error) {
        dispatch(editWalletMinimumBalancesSuccessAction());
        // dispatch(getUserWallet({ userId, setData }));
      } else {
        dispatch(
          editWalletMinimumBalancesFailureAction(
            response.data.error_description
          )
        );
      }
    } catch (error) {
      dispatch(editWalletMinimumBalancesFailureAction(error.error_description));
    }
  };
};

export const editWalletMinimumBalanceStartedAction = () => ({
  type: EDIT_WALLET_MINIMUM_BALANCE_STARTED,
});

export const editWalletMinimumBalancesSuccessAction = (payload) => ({
  type: EDIT_WALLET_MINIMUM_BALANCE_SUCCESS,
  payload,
});

export const editWalletMinimumBalancesFailureAction = (message) => ({
  type: EDIT_WALLET_MINIMUM_BALANCE_FAILURE,
  payload: message,
});

export const getUserPhysicalCards = ({ setData, userId }) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserPhysicalCardAPI({ userId });
      if (!response.error) {
        dispatch(getUsersSuccessAction());
        setData(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};
export const getUserVirtualCards = ({ setData, userId }) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserVirtualCardAPI({ userId });
      if (!response.error) {
        dispatch(getUsersSuccessAction());
        setData(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};
export const getUserTransactions = ({
  setData,
  userId,
  from,
  to,
  limit,
  offset,
}) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserTransactionsAPI({
        userId,
        from,
        to,
        limit,
        offset,
      });
      if (!response.error) {
        dispatch(getUsersSuccessAction(response.data));
        setData(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};
export const getUserLogs = ({ setData, userId, from, to, limit, offset }) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserLogsAPI({
        userId,
        from,
        to,
        limit,
        offset,
      });
      if (!response.error) {
        dispatch(getUsersSuccessAction(response.data));
        setData(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};

export const getCardTransactions = ({
  setData,
  cardId,
  from,
  to,
  limit,
  offset,
}) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getCardTransactionsAPI({
        cardId,
        from,
        to,
        limit,
        offset,
      });
      if (!response.error) {
        dispatch(getUsersSuccessAction(response.data));
        setData(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};

export const getUserDefaultFeeAction = (data, setValues) => {
  return async (dispatch) => {
    dispatch(getUserDefaultsStartedAction());
    try {
      let response = await getUserDefaultFeeAPI(data);
      if (!response.error) {
        dispatch(getUsersSuccessAction(response.data));
        setValues(response.data);
      } else {
        dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      dispatch(getUsersFailureAction(error.error_description));
    }
  };
};
export const deleteUserFeeAction = (data, setData) => {
  return async (dispatch) => {
    try {
      let response = await deleteUserFeesAPI(data);
      if (!response.error) {
        dispatch(getUserFeesRequestAction(setData));
        // dispatch(getUsersSuccessAction(response.data));
      } else {
        // dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      // dispatch(getUsersFailureAction(error.error_description));
    }
  };
};
export const getUsersRegistrationAction = (setData) => {
  return async (dispatch) => {
    try {
      let response = await getUsersRegistrationAPI();
      if (!response.error) {
        setData(response.data);
        // dispatch(getUsersSuccessAction(response.data));
      } else {
        // dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      // dispatch(getUsersFailureAction(error.error_description));
    }
  };
};
export const getUsersSettingsAction = (userId, setSettings) => {
  return async (dispatch) => {
    try {
      let response = await getUsersSettingsAPI(userId);
      if (!response.error) {
        setSettings(response.data);
        // dispatch(getUsersSuccessAction(response.data));
      } else {
        // dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      // dispatch(getUsersFailureAction(error.error_description));
    }
  };
};
export const setUsersSettingsAction = (settings) => {
  return async (dispatch) => {
    try {
      let response = await setUsersSettingsAPI(settings);
      if (!response.error) {
        showMessage({
          status: "success",
        });
        // dispatch(getUsersSuccessAction(response.data));
      } else {
        // dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      // dispatch(getUsersFailureAction(error.error_description));
    }
  };
};
export const getUserFeesListAction = (userId, setFees) => {
  return async (dispatch) => {
    try {
      let response = await getUserFeesListAPI(userId);
      if (!response.error) {
        setFees(response.data);
        // dispatch(getUsersSuccessAction(response.data));
      } else {
        // dispatch(getUsersFailureAction(response.data.error_description));
      }
    } catch (error) {
      // dispatch(getUsersFailureAction(error.error_description));
    }
  };
};

// --------------------------------------------------------------------------------
// export const editUserDefaultWalletRequestAction = ({ walletId }) => {
//   return async (dispatch) => {
//     dispatch(editUserDefaultWalletStartedAction());
//     try {
//       let response = await editUserDefaultWalletAPI({ walletId });
//       if (!response.error) {
//         dispatch(editUserDefaultWalletSuccessAction());
//       } else {
//         dispatch(
//           editUserDefaultWalletFailureAction(response.data.error_description)
//         );
//       }
//     } catch (error) {
//       dispatch(editUserDefaultWalletFailureAction(error.error_description));
//     }
//   };
// };

// export const editUserDefaultWalletStartedAction = () => ({
//   type: CREATE_USER_DEFAULT_WALLET_STARTED,
// });

// export const editUserDefaultWalletSuccessAction = (payload) => ({
//   type: CREATE_USER_DEFAULT_WALLET_SUCCESS,
//   payload,
// });

// export const editUserDefaultWalletFailureAction = (message) => ({
//   type: CREATE_USER_DEFAULT_WALLET_FAILURE,
//   payload: message,
// });
// --------------------------------------------------------------------------------
export const getUserDocumentsRequestAction = ({ userId, setData }) => {
  return async (dispatch) => {
    dispatch(getUserDocumentsStartedAction());
    // setLoading(true);
    try {
      let response = await getUserDocumentsAPI({ userId });
      if (!response.error) {
        // setLoading(false);
        dispatch(getUserDocumentsSuccessAction());
        setData(response.data);
        // onSuccess();
      } else {
        // setLoading(false);
        dispatch(
          getUserDocumentsFailureAction(response.data.error_description)
        );
      }
    } catch (error) {
      // setLoading(false);
      dispatch(getUserDocumentsFailureAction(error.error_description));
    }
  };
};

export const getUserDocumentsStartedAction = () => ({
  type: GET_USER_DOCUMENTS_STARTED,
});

export const getUserDocumentsSuccessAction = (payload) => ({
  type: GET_USER_DOCUMENTS_SUCCESS,
  payload,
});

export const getUserDocumentsFailureAction = (message) => ({
  type: GET_USER_DOCUMENTS_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const downloadFileRequestAction = ({ fileAddress, onSuccess }) => {
  return async (dispatch) => {
    dispatch(downloadFileStartedAction());
    // setLoading(true);
    try {
      let response = await downloadFileAPI({ fileAddress });
      if (!response.error) {
        // setLoading(false);
        dispatch(downloadFileSuccessAction());
        onSuccess(response);
      } else {
        // setLoading(false);
        dispatch(downloadFileFailureAction(response.data.error_description));
      }
    } catch (error) {
      // setLoading(false);
      dispatch(downloadFileFailureAction(error.error_description));
    }
  };
};

export const downloadFileStartedAction = () => ({
  type: DOWNLOAD_FILE_STARTED,
});

export const downloadFileSuccessAction = (payload) => ({
  type: DOWNLOAD_FILE_SUCCESS,
  payload,
});

export const downloadFileFailureAction = (message) => ({
  type: DOWNLOAD_FILE_FAILURE,
  payload: message,
});
// --------------------------------------------------------------------------------
export const uploadFileRequestAction = ({
  formData,
  setUploadPercent = () => {},
  setDisabled = () => {},
  onSuccess = () => {},
}) => {
  return async (dispatch) => {
    dispatch(uploadFileStartedAction());
    setDisabled(true);
    try {
      let response = await uploadFileAPI(formData, (uploadPercent) =>
        setUploadPercent(uploadPercent)
      );
      if (response.status === "success") {
        setDisabled(false);
        onSuccess(response.data);
      } else {
        uploadFileFailureAction(response.data.error_description);
        setDisabled(false);
      }
    } catch (error) {
      dispatch(uploadFileFailureAction(error.error_description));
      setDisabled(false);
    }
  };
};

export const uploadFileStartedAction = () => ({
  type: UPLOAD_FILE_STARTED,
});

export const uploadFileSuccessAction = (payload) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload,
});

export const uploadFileFailureAction = (message) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: message,
});
